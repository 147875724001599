package app.megachat.website.browser

import org.koin.dsl.module

internal actual val browserPlatformModule = module {

  single<Browser> {
    BrowserImpl()
  }
}
