package app.megachat.client.ui.design.emoji

import kotlinx.serialization.json.Json
import org.koin.dsl.module

internal val emojiModule = module {
  single {
    EmojiRepository(
      coroutineScope = get(),
      json = Json {
        ignoreUnknownKeys = true
      },
    )
  }
}
