package app.megachat.website.main

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.displayCutout
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.offset
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import app.megachat.client.ui.core.navigation.Content
import app.megachat.client.ui.design.components.TopIndicator
import app.megachat.client.ui.design.theme.AppTheme
import app.megachat.client.ui.design.theme.Dimensions.Gap200
import app.megachat.website.main.Main.State

@Composable
internal fun MainContent(
  state: State,
  modifier: Modifier = Modifier,
) {
  AppTheme(
    theme = AppTheme.Type.Dark,
    imageLoaders = state.imageLoaders,
  ) {
    Box(
      modifier = modifier
        .fillMaxSize(), // to prevent growing animation
    ) {
      Content(
        navigator = state.navigator,
      )

      TopIndicators(
        environmentName = state.showEnvironmentName,
        isDebug = state.isDebug,
      )
    }
  }
}

@Composable
private fun BoxScope.TopIndicators(
  environmentName: String?,
  isDebug: Boolean,
) {
  if (isDebug || !environmentName.isNullOrBlank()) {
    val hasCutouts = WindowInsets.displayCutout.getTop(LocalDensity.current) > 0

    Row(
      modifier = (Modifier as Modifier)
        .run {
          if (hasCutouts) align(Alignment.TopStart).offset(x = Gap200)
          else align(Alignment.TopCenter)
        }
    ) {
      if (isDebug) {
        TopIndicator(
          text = "DEBUG",
          backgroundColor = Color.Cyan,
        )
      }
      if (environmentName != null) {
        TopIndicator(
          text = environmentName,
          backgroundColor = Color.Yellow,
        )
      }
    }
  }
}
