package app.megachat.website.about

import androidx.compose.runtime.Composable
import app.megachat.client.ui.core.navigation.Navigator
import app.megachat.client.ui.core.navigation.Presenter
import app.megachat.website.about.About.Event
import app.megachat.website.about.About.State

internal class AboutPresenter(
  private val navigator: Navigator,
) : Presenter<State, Event> {

  @Composable
  override fun present(): State {
    return State { event ->
      when (event) {
        is Event.GoBack -> navigator.pop()
      }
    }
  }
}
