package app.megachat.client.ui.design.util

import androidx.compose.runtime.Stable
import androidx.compose.ui.geometry.Offset
import kotlin.math.cos
import kotlin.math.min
import kotlin.math.sin

@Stable
val Offset.minDimension: Float
  inline get() = min(x, y)

@Stable
fun Offset.rotateBy(angle: Angle): Offset =
  angle.radians.let { radians ->
    val cos = cos(radians)
    val sin = sin(radians)
    Offset(
      x = (x * cos - y * sin),
      y = (x * sin + y * cos)
    )
  }
