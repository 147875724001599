package app.megachat.website.welcome

import androidx.compose.runtime.Immutable
import app.megachat.client.ui.core.koin.factory
import app.megachat.client.ui.core.navigation.Destination
import app.megachat.client.ui.core.navigation.Ui
import app.megachat.client.ui.core.navigation.UiEvent
import app.megachat.client.ui.core.navigation.UiState
import app.megachat.client.ui.design.user.UserSummary
import app.megachat.shared.base.data.UserId
import app.megachat.shared.base.koin.rootScope
import app.megachat.shared.base.parcel.Parcelize
import app.megachat.shared.base.util.emptyImmutableMap
import app.megachat.shared.base.util.lazyPub
import kotlinx.collections.immutable.ImmutableMap
import org.koin.dsl.module

@Parcelize
data object WelcomeScreen : Destination(
  presentation = Presentation.FullScreen(isRoot = true),
)

internal object Welcome {

  @Immutable
  data class State(
    val boidUsers: ImmutableMap<UserId, UserSummary>,
    val version: String,
    override val send: (Event) -> Unit,
  ) : UiState<Event> {

    companion object {
      val EMPTY by lazyPub {
        State(
          boidUsers = emptyImmutableMap(),
          version = "",
          send = {},
        )
      }
    }
  }

  sealed interface Event : UiEvent {
    data object OnPrivacyPolicyClick : Event
    data object OnTermsAndConditionsClick : Event
    data object OnAboutClick : Event
  }

  val Module = module {
    factory<WelcomeScreen>(
      scope = { rootScope },
      presenter = { _, navigator ->
        WelcomePresenter(
          browser = get(),
          buildConfig = get(),
          navigator = navigator,
        )
      },
      ui = { _ ->
        Ui<State, Event> { state, modifier ->
          WelcomeContent(state, modifier)
        }
      },
    )
  }
}
