package app.megachat.website.welcome

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import app.megachat.client.ui.design.components.Scaffold
import app.megachat.client.ui.design.components.Text
import app.megachat.client.ui.design.components.VerticalDivider
import app.megachat.client.ui.design.theme.AppTheme
import app.megachat.client.ui.design.theme.Dimensions.Gap100
import app.megachat.client.ui.design.theme.Dimensions.Gap200
import app.megachat.client.ui.design.theme.Dimensions.Gap50
import app.megachat.client.ui.design.theme.Dimensions.MaxColumnWidth
import app.megachat.client.ui.design.theme.Dimensions.MaxContentWidth
import app.megachat.client.ui.design.theme.Shapes
import app.megachat.client.ui.design.theme.defaultBackground
import app.megachat.client.ui.design.user.boid.UserBoids
import app.megachat.client.ui.design.user.boid.rememberUserBoidsState
import app.megachat.client.ui.design.util.safeDrawingWithoutIme
import app.megachat.website.welcome.Welcome.Event
import app.megachat.website.welcome.Welcome.State
import megachat.websiteapp.generated.resources.Res
import megachat.websiteapp.generated.resources.app_logo_transparent_512
import megachat.websiteapp.generated.resources.app_store_button
import megachat.websiteapp.generated.resources.google_play_button
import org.jetbrains.compose.resources.imageResource

@OptIn(ExperimentalLayoutApi::class)
@Composable
internal fun WelcomeContent(
  state: State,
  modifier: Modifier = Modifier,
) {
  Scaffold(
    modifier = modifier
      .defaultBackground()
      .windowInsetsPadding(WindowInsets.safeDrawingWithoutIme),
  ) { contentPadding ->
    Column(
      modifier = Modifier
        .padding(contentPadding)
        .fillMaxSize(),
      horizontalAlignment = Alignment.CenterHorizontally,
    ) {
      Column(
        modifier = Modifier.weight(3f),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
      ) {
        Image(
          modifier = Modifier
            .fillMaxSize()
            .padding(horizontal = Gap200)
            .sizeIn(maxWidth = MaxColumnWidth),
          bitmap = imageResource(Res.drawable.app_logo_transparent_512),
          contentDescription = null,
        )
      }

      Box(
        modifier = Modifier
          .weight(2f)
          .widthIn(max = MaxContentWidth)
          .fillMaxWidth(),
      ) {
        FlowRow(
          modifier = Modifier
            .fillMaxWidth(),
          horizontalArrangement = Arrangement.spacedBy(Gap100, Alignment.CenterHorizontally),
          verticalArrangement = Arrangement.spacedBy(Gap100)
        ) {
          Image(
            bitmap = imageResource(Res.drawable.app_store_button),
            contentDescription = null,
          )
          Image(
            bitmap = imageResource(Res.drawable.google_play_button),
            contentDescription = null,
          )
        }

        Footer(
          modifier = Modifier
            .align(Alignment.BottomCenter),
          version = state.version,
          onPrivacyPolicyClick = { state.send(Event.OnPrivacyPolicyClick) },
          onTermsAndConditionsClick = { state.send(Event.OnTermsAndConditionsClick) },
          onAboutClick = { state.send(Event.OnAboutClick) }
        )

        UserBoids(
          modifier = Modifier
            .padding(bottom = Gap200),
          state = rememberUserBoidsState(
            userIds = state.boidUsers.keys,
            animated = true,
          ),
          users = state.boidUsers,
          onBoidClick = { }, //TODO state.send(Event.OnUserClick(it)) },
        )
      }
    }
  }
}

@Composable
private fun Footer(
  version: String,
  onPrivacyPolicyClick: () -> Unit,
  onTermsAndConditionsClick: () -> Unit,
  onAboutClick: () -> Unit,
  modifier: Modifier = Modifier,
) {
  Column(
    modifier = modifier
      .padding(Gap50),
    horizontalAlignment = Alignment.CenterHorizontally,
  ) {
    val fontSize = 12.sp
    Row(
      modifier = Modifier
        .height(IntrinsicSize.Min),
      verticalAlignment = Alignment.CenterVertically,
    ) {
      val color = AppTheme.onBackgroundColor.copy(alpha = 0.75f)
      Text(
        modifier = Modifier
          .clip(Shapes.pill)
          .clickable { onPrivacyPolicyClick() }
          .padding(Gap50),
        text = "Privacy Policy",
        color = color,
        fontSize = fontSize,
        lineHeight = fontSize,
      )
      VerticalDivider(
        modifier = Modifier.height(10.dp),
        color = color,
      )
      Text(
        modifier = Modifier
          .clip(Shapes.pill)
          .clickable { onTermsAndConditionsClick() }
          .padding(Gap50),
        color = color,
        text = "Terms & Conditions",
        fontSize = fontSize,
        lineHeight = fontSize,
      )
      VerticalDivider(
        modifier = Modifier.height(10.dp),
        color = color,
      )
      Text(
        modifier = Modifier
          .clip(Shapes.pill)
          .clickable { onAboutClick() }
          .padding(Gap50),
        color = color,
        text = "About",
        fontSize = fontSize,
        lineHeight = fontSize,
      )
    }
    Row(
      modifier = Modifier
        .height(IntrinsicSize.Min),
      verticalAlignment = Alignment.CenterVertically,
    ) {
      val color = AppTheme.onBackgroundColor.copy(alpha = 0.5f)
      Text(
        modifier = Modifier
          .padding(horizontal = Gap50),
        text = "Version $version",
        color = color,
        fontSize = fontSize,
        lineHeight = fontSize,
      )
      VerticalDivider(
        modifier = Modifier.height(10.dp),
        color = color,
      )
      Text(
        modifier = Modifier
          .padding(horizontal = Gap50),
        color = color,
        text = "© 2025 MEGA CHAT, LLC",
        fontSize = fontSize,
        lineHeight = fontSize,
      )
    }
  }
}
