package app.megachat.client.ui.core.util

import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.pointer.SuspendingPointerInputModifierNode
import androidx.compose.ui.node.CompositionLocalConsumerModifierNode
import androidx.compose.ui.node.DelegatingNode
import androidx.compose.ui.node.ModifierNodeElement
import androidx.compose.ui.node.currentValueOf
import androidx.compose.ui.platform.InspectorInfo
import androidx.compose.ui.platform.LocalSoftwareKeyboardController

fun Modifier.tapToDismissKeyboard(): Modifier =
  this then TapToDismissKeyboardElement

fun Modifier.consumeTaps(): Modifier =
  tapToDismissKeyboard()

private data object TapToDismissKeyboardElement : ModifierNodeElement<TapToDismissKeyboardNode>() {
  override fun create(): TapToDismissKeyboardNode = TapToDismissKeyboardNode()

  override fun update(node: TapToDismissKeyboardNode) = Unit

  override fun InspectorInfo.inspectableProperties() {
    name = "tapToDismissKeyboard"
  }
}

private class TapToDismissKeyboardNode : DelegatingNode() {
  private val keyboardNode = delegate(DismissKeyboardNode())
  private val pointerInput = delegate(
    SuspendingPointerInputModifierNode {
      detectTapGestures { keyboardNode.hide() }
    }
  )
}

private class DismissKeyboardNode : Modifier.Node(), CompositionLocalConsumerModifierNode {
  fun hide() {
    currentValueOf(LocalSoftwareKeyboardController)?.hide()
  }
}
