package app.megachat.client.ui.design.material.snackbar

import androidx.compose.material3.SnackbarResult
import androidx.compose.material3.SnackbarVisuals
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import app.megachat.client.ui.design.util.SnackbarStyle
import kotlin.coroutines.resume
import kotlinx.coroutines.CancellableContinuation
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

//
// Copied from material-1.6.11 and modified, adding style to data.
//
@Stable
internal class SnackbarHostState {
  private val mutex = Mutex()

  var currentSnackbarData by mutableStateOf<SnackbarData?>(null)
    private set

  suspend fun showSnackbar(
    visuals: SnackbarVisuals,
    style: SnackbarStyle, // Added
  ): SnackbarResult = mutex.withLock {
    try {
      return suspendCancellableCoroutine { continuation ->
        currentSnackbarData = SnackbarDataImpl(visuals, style, continuation)
      }
    } finally {
      currentSnackbarData = null
    }
  }

  internal interface SnackbarData : androidx.compose.material3.SnackbarData {
    val style: SnackbarStyle // Added
  }

  @Stable
  private class SnackbarDataImpl(
    override val visuals: SnackbarVisuals,
    override val style: SnackbarStyle, // Added
    private val continuation: CancellableContinuation<SnackbarResult>
  ) : SnackbarData {
    override fun performAction() {
      if (continuation.isActive) continuation.resume(SnackbarResult.ActionPerformed)
    }

    override fun dismiss() {
      if (continuation.isActive) continuation.resume(SnackbarResult.Dismissed)
    }
  }
}
