package app.megachat.shared.base.koin

import app.megachat.shared.base.util.alsoIf
import kotlin.DeprecationLevel.ERROR
import org.koin.core.definition.Definition
import org.koin.core.definition.KoinDefinition
import org.koin.core.instance.InstanceFactory
import org.koin.core.qualifier.Qualifier
import org.koin.dsl.ScopeDSL

@Suppress("unused", "unused_parameter")
class UserScopeDsl(
  val scopeDSL: ScopeDSL,
  val onEagerInstance: (instanceFactory: InstanceFactory<*>) -> Unit,
) {
  @Deprecated("Can't use Single in a scope. Use Scoped instead", level = ERROR, replaceWith = ReplaceWith("scoped"))
  inline fun <reified T> single(
    qualifier: Qualifier? = null,
    noinline definition: Definition<T>
  ): KoinDefinition<T> =
    error("Deprecated")

  inline fun <reified T: Any> scoped(
    qualifier: Qualifier? = null,
    createdAtStart: Boolean = false,
    noinline definition: Definition<T>
  ): KoinDefinition<T> =
    scopeDSL.scoped(qualifier) { params ->
      definition(params)
    }
      .alsoIf(createdAtStart) {
        onEagerInstance(it.factory)
      }

  inline fun <reified T: Any> factory(
    qualifier: Qualifier? = null,
    noinline definition: Definition<T>
  ): KoinDefinition<T> =
    scopeDSL.factory(qualifier) { params ->
      definition(params)
    }
}
