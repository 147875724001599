package app.megachat.client.ui.design.theme

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.automirrored.filled.ExitToApp
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Check
import androidx.compose.material.icons.filled.CheckCircle
import androidx.compose.material.icons.filled.Clear
import androidx.compose.material.icons.filled.Close
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material.icons.filled.Email
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material.icons.filled.Person
import androidx.compose.material.icons.filled.Search
import androidx.compose.material.icons.filled.Settings
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.rememberVectorPainter
import app.megachat.client.ui.design.material.icons.filled.AutoAwesome
import app.megachat.client.ui.design.material.icons.filled.Block
import app.megachat.client.ui.design.material.icons.filled.Bunny
import app.megachat.client.ui.design.material.icons.filled.Cancel
import app.megachat.client.ui.design.material.icons.filled.Circle
import app.megachat.client.ui.design.material.icons.filled.Colorize
import app.megachat.client.ui.design.material.icons.filled.People
import app.megachat.client.ui.design.material.icons.filled.PersonSearch
import app.megachat.client.ui.design.material.icons.filled.StopCircle
import app.megachat.client.ui.design.material.icons.filled.SwitchAccount
import app.megachat.client.ui.design.material.icons.filled.TrendingUp
import app.megachat.client.ui.design.material.icons.filled.Visibility
import app.megachat.client.ui.design.material.icons.filled.VisibilityOff
import app.megachat.client.ui.design.material.icons.outlined.PauseCircle
import app.megachat.client.ui.design.material.icons.outlined.PlayCircle

object Icons {

  object Common {

    val Add: Painter
      @Composable get() = Add_

    val CaretEnd: Painter
      @Composable get() = CaretEnd_

    val CaretStart: Painter
      @Composable get() = CaretStart_

    val Chat: Painter
      @Composable get() = Chat_

    val Check: Painter
      @Composable get() = Check_

    val Clear: Painter
      @Composable get() = Clear_

    val Delete: Painter
      @Composable get() = Delete_

    val Dropper: Painter
      @Composable get() = Colorize_

    val Email: Painter
      @Composable get() = Email_

    val Environments: Painter
      @Composable get() = Bunny_

    val Gif: Painter
      @Composable get() = Gif_

    val Invisible: Painter
      @Composable get() = VisibilityOff_

    val More: Painter
      @Composable get() = MoreVert_

    val Pencil: Painter
      @Composable get() = Edit_

    val Plus: Painter
      @Composable get() = Plus_

    val ScrollDown: Painter
      @Composable get() = ScrollDown_

    val Search: Painter
      @Composable get() = Search_

    val Settings: Painter
      @Composable get() = Settings_

    val SignOut: Painter
      @Composable get() = ExitToApp_

    val Sparkles: Painter
      @Composable get() = AutoAwesome_

    val TextStyle: Painter
      @Composable get() = TextStyle_

    val Trending: Painter
      @Composable get() = TrendingUp_

    val Visible: Painter
      @Composable get() = Visibility_
  }

  object Navigation {

    val Back: Painter
      @Composable get() = ArrowBack_

    val Close: Painter
      @Composable get() = Close_
  }

  object Chat {

    val Send: Painter
      @Composable get() = Send_
  }

  object User {

    val Block: Painter
      @Composable get() = Block_

    val Friend: Painter
      @Composable get() = Person_

    val Ignore: Painter
      @Composable get() = PauseCircle_

    val Impersonate: Painter
      @Composable get() = SwitchAccount_

    val Befriend: Painter
      @Composable get() = Befriend_

    val Relationships: Painter
      @Composable get() = People_

    val Search: Painter
      @Composable get() = PersonSearch_

    val Unblock: Painter
      @Composable get() = CheckCircle_

    val Unfriend: Painter
      @Composable get() = Unfriend_

    val Unignore: Painter
      @Composable get() = PlayCircle_

    val Uninvite: Painter
      @Composable get() = Cancel_
  }

  internal val Add_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Add)

  internal val ArrowBack_: Painter
    @Composable get() = rememberVectorPainter(Icons.AutoMirrored.Filled.ArrowBack)

  internal val AutoAwesome_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.AutoAwesome)

  internal val Befriend_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Befriend)

  internal val Block_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Block)

  internal val Bunny_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Bunny)

  internal val Cancel_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Cancel)

  internal val CaretEnd_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.CaretEnd)

  internal val CaretStart_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.CaretStart)

  internal val Chat_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Chat)

  internal val Check_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Check)

  internal val CheckCircle_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.CheckCircle)

  internal val Circle: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Circle)

  internal val Clear_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Clear)

  internal val Close_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Close)

  internal val Colorize_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Colorize)

  internal val Delete_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Delete)

  internal val Edit_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Edit)

  internal val Email_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Email)

  internal val ExitToApp_: Painter
    @Composable get() = rememberVectorPainter(Icons.AutoMirrored.Filled.ExitToApp)

  internal val Gif_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Gif)

  internal val MoreVert_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.MoreVert)

  internal val People_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.People)

  internal val Person_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Person)

  internal val PersonSearch_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.PersonSearch)

  internal val PauseCircle_: Painter
    @Composable get() = rememberVectorPainter(Icons.Outlined.PauseCircle)

  internal val PlayCircle_: Painter
    @Composable get() = rememberVectorPainter(Icons.Outlined.PlayCircle)

  internal val Plus_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Plus)

  internal val ScrollDown_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.ScrollDown)

  internal val Search_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Search)

  internal val Send_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Send)

  internal val Settings_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Settings)

  internal val StopCircle_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.StopCircle)

  internal val SwitchAccount_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.SwitchAccount)

  internal val TextStyle_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.TextStyle)

  internal val TrendingUp_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.TrendingUp)

  internal val Unfriend_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Unfriend)

  internal val Visibility_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Visibility)

  internal val VisibilityOff_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.VisibilityOff)
}
