package app.megachat.client.ui.design.util

import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.border
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ProgressIndicatorDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.graphics.drawscope.withTransform
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.LayoutDirection
import app.megachat.client.ui.design.draw.angledSweepGradient
import app.megachat.client.ui.design.theme.Shapes
import app.megachat.shared.base.util.runIf

@Composable
fun Modifier.bottomBorder(width: Dp, color: Color): Modifier =
  this then border(BorderStroke(width, color), shape = Shapes.bottomRect(width))

fun Modifier.dashDotBorder(
  brush: Brush,
  shape: Shape,
  width: Dp,
): Modifier =
  this then drawWithContent {
    drawContent()

    val widthPx: Float = width.toPx()
    withTransform({
      val halfStroke = widthPx / 2
      inset(halfStroke, halfStroke, halfStroke, halfStroke)
      //rotate(-90f)
    }) {
      drawPath(
        path = shape.toPath(size, LayoutDirection.Ltr, density = this@drawWithContent),
        brush = brush,
        style = Stroke(
          width = widthPx,
          cap = StrokeCap.Round,
          pathEffect = PathEffect.dashPathEffect(
            intervals = floatArrayOf(widthPx * 2f, widthPx * 1.5f, 0f, widthPx * 1.5f),
            //floatArrayOf(widthPx * 1.63f, widthPx * 1.68f, 0f, widthPx * 1.63f),
          )
        ),
      )
    }
  }

fun Modifier.dashedBorder(
  brush: Brush,
  shape: Shape,
  width: Dp,
): Modifier =
  this then drawWithContent {
    drawContent()

    val widthPx: Float = width.toPx()
    withTransform({
      val halfStroke = widthPx / 2
      inset(halfStroke, halfStroke, halfStroke, halfStroke)
      //rotate(92.5f)
    }) {
      drawPath(
        path = shape.toPath(size, LayoutDirection.Ltr, density = this@drawWithContent),
        brush = brush,
        style = Stroke(
          width = widthPx,
          cap = StrokeCap.Round,
          pathEffect = PathEffect.dashPathEffect(
            intervals = floatArrayOf(widthPx * 1.5f, widthPx * 3f),
            //floatArrayOf(widthPx * 1.46f, widthPx * 3.23f),
          )
        ),
      )
    }
  }

fun Modifier.dottedBorder(
  brush: Brush,
  shape: Shape,
  width: Dp,
): Modifier =
  this then drawWithContent {
    drawContent()

    val widthPx: Float = width.toPx()
    withTransform({
      val halfStroke = widthPx / 2
      inset(halfStroke, halfStroke, halfStroke, halfStroke)
      //rotate(90f)
    }) {
      drawPath(
        path = shape.toPath(size, LayoutDirection.Ltr, density = this@drawWithContent),
        brush = brush,
        style = Stroke(
          width = widthPx,
          cap = StrokeCap.Round,
          pathEffect = PathEffect.dashPathEffect(
            intervals = floatArrayOf(0f, widthPx * 1.5f),
            // floatArrayOf(0f, widthPx * 1.51f),
          )
        ),
      )
    }
  }

@Composable
fun Modifier.progressBorder(
  enabled: Boolean = true,
  color: Color = MaterialTheme.colorScheme.primary,
  backgroundColor: Color = Color.Transparent,
  strokeWidth: Dp = ProgressIndicatorDefaults.CircularStrokeWidth,
  shape: Shape = CircleShape,
): Modifier =
  runIf(enabled) {
    this then composed {

      val infiniteTransition = rememberInfiniteTransition()
      val rotationDegrees = infiniteTransition.animateFloat(
        initialValue = 0f,
        targetValue = 360f,
        animationSpec = infiniteRepeatable(
          animation = tween(1_500, easing = LinearEasing),
          repeatMode = RepeatMode.Restart
        )
      ).value

      val brush = Brush.angledSweepGradient(
        0f to color,
        0.25f to color,
        0.2501f to backgroundColor,
        startAngle = rotationDegrees,
      )

      drawWithContent {
        drawContent()

        val strokeWidthPx = strokeWidth.toPx()
        withTransform({
          val halfStroke = strokeWidthPx / 2
          inset(halfStroke, halfStroke, halfStroke, halfStroke)
        }) {
          drawPath(
            shape.toPath(size, layoutDirection, density = this),
            brush,
            style = Stroke(
              width = strokeWidthPx,
            ),
          )
        }
      }
    }
  }
