package app.megachat.client.ui.design.util

import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.displayCutout
import androidx.compose.foundation.layout.systemBars
import androidx.compose.foundation.layout.union
import androidx.compose.runtime.Composable

val WindowInsets.Companion.safeDrawingWithoutIme
  @Composable get() = systemBars.union(displayCutout)
