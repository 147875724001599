package app.megachat.client.ui.design.util

import kotlin.jvm.JvmInline
import kotlin.math.PI
import kotlin.math.absoluteValue
import kotlin.math.roundToInt

@JvmInline
value class Angle(private val seconds: Int) : Comparable<Angle> {

  val degrees: Float
    get() = seconds.toFloat() / SECONDS_IN_ONE_DEGREE

  val radians: Float
    get() = degrees / DEGREES_IN_ONE_RADIAN

  operator fun plus(other: Angle): Angle =
    Angle(seconds + other.seconds)

  operator fun minus(other: Angle): Angle =
    Angle(seconds - other.seconds)

  operator fun unaryMinus(): Angle =
    Angle(-seconds)

  operator fun times(other: Float): Angle =
    Angle((seconds * other).roundToInt())

  operator fun times(other: Int): Angle =
    Angle(seconds * other)

  operator fun div(other: Float): Angle =
    Angle((seconds / other).roundToInt())

  operator fun div(other: Int): Angle =
    Angle(seconds / other)

  val absolute: Angle
    get() = Angle(seconds.absoluteValue)

  override operator fun compareTo(other: Angle): Int =
    degrees.compareTo(other.degrees)

  val isNormalized: Boolean
    get() = seconds in 0 until SECONDS_IN_FULL_CIRCLE

  /**
   * Returns an angle from positive zero (inclusive) to 360 (exclusive).
   */
  fun normalize(): Angle =
    Angle(
      (seconds % SECONDS_IN_FULL_CIRCLE).let { remainder ->
        if (remainder < 0) remainder + SECONDS_IN_FULL_CIRCLE else remainder
      }
    )

  override fun toString() =
    "Angle(degrees=$degrees)"

  /**
   * Returns a positive or negative angle from the nearest multiple of the `every` angle.
   */
  fun snapTo(every: Angle): Angle {
    require(isNormalized)
    require(every.isNormalized && every.seconds > 0)

    return Angle(
      (seconds % every.seconds).let { remainder ->
        if (remainder < every.seconds / 2) remainder else remainder - every.seconds
      }
    )
  }

  companion object {
    val Zero = Angle(seconds = 0)
    val Full = 360.degrees

    private const val SECONDS_IN_ONE_MINUTE = 60
    private const val MINUTES_IN_ONE_DEGREE = 60
    internal const val SECONDS_IN_ONE_DEGREE =
      SECONDS_IN_ONE_MINUTE * MINUTES_IN_ONE_DEGREE // == 3_600

    private const val DEGREES_IN_FULL_CIRCLE = 360
    private const val SECONDS_IN_FULL_CIRCLE =
      SECONDS_IN_ONE_DEGREE * DEGREES_IN_FULL_CIRCLE // == 1_296_000

    const val MAX_DEGREES: Int = Int.MAX_VALUE / SECONDS_IN_ONE_DEGREE // == 596_523
    const val MIN_DEGREES: Int = Int.MIN_VALUE / SECONDS_IN_ONE_DEGREE // == -596_523

    const val DEGREES_IN_ONE_RADIAN: Float = 180f / PI.toFloat() // ~= 57.2957795131

    const val MAX_RADIANS: Float = MAX_DEGREES / DEGREES_IN_ONE_RADIAN // ~= 10_411
    const val MIN_RADIANS: Float = MIN_DEGREES / DEGREES_IN_ONE_RADIAN // ~= -10_411
  }
}
