package app.megachat.client.ui.core.koin

import app.megachat.client.ui.core.navigation.Destination
import app.megachat.client.ui.core.navigation.Navigator
import app.megachat.client.ui.core.navigation.Presenter
import app.megachat.client.ui.core.navigation.Ui
import com.slack.circuit.runtime.CircuitContext
import org.koin.core.module.Module
import org.koin.core.qualifier.TypeQualifier
import org.koin.core.scope.Scope
import com.slack.circuit.runtime.presenter.Presenter as CircuitPresenter
import com.slack.circuit.runtime.ui.Ui as CircuitUi

inline fun <reified D : Destination> Module.factory(
  crossinline scope: (D) -> Scope,
  crossinline presenter: Scope.(D, Navigator) -> Presenter<*, *>,
  crossinline ui: Scope.(D) -> Ui<*, *>,
) {
  factory<CircuitPresenter<*>>(TypeQualifier(D::class)) { (destination: D, navigator: Navigator, _: CircuitContext) ->
    scope(destination).presenter(destination, navigator)
  }
  factory<CircuitUi<*>>(TypeQualifier(D::class)) { (destination: D, _: CircuitContext) ->
    scope(destination).ui(destination)
  }
}
