package app.megachat.client.ui.design.util

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Outline
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.util.lerp
import kotlin.math.acos
import kotlin.math.cos
import kotlin.math.sqrt

class RoundedPolygonShape(
  private val edgeCount: Int,
  private val percent: Float,
  private val centerY: Float = 0.5f,
  private val scale: Float = 1f,
  private val angle: Angle = Angle.Zero,
) : Shape {

  init {
    require(percent in 0f..100f)
  }

  override fun createOutline(
    size: Size,
    layoutDirection: LayoutDirection,
    density: Density
  ): Outline =
    Outline.Generic(
      Path().apply {
        val fraction = percent / 100
        val center = Offset(size.width / 2, size.height * centerY)
        val theta = Angle.Full / edgeCount

        val maxRadius = center.minDimension * scale
        val minRadius = maxRadius * cos(theta.radians / 2)
        val edgeHalf = sqrt(maxRadius * maxRadius - minRadius * minRadius)

        val tipRadius = sqrt((edgeHalf * (1 - fraction)).let { it * it } + minRadius * minRadius)
        val lineTip = Offset(0f, -tipRadius).rotateBy(angle)

        val roundingThetaHalf = theta / 2 - acos(minRadius / tipRadius).radians
        val roundingArcRadius = lerp(0f, minRadius, fraction)
        val roundingCenterRadius = lerp(maxRadius, 0f, fraction)
        val roundingCenter = Offset(0f, -roundingCenterRadius).rotateBy(angle)

        moveTo(center + lineTip.rotateBy(-roundingThetaHalf))

        repeat(edgeCount) { vertex ->
          arcTo(
            rect = Rect(
              center = center + roundingCenter.rotateBy(theta * vertex),
              radius = roundingArcRadius,
            ),
            startAngleDegrees = -90 + (angle + theta * vertex - theta / 2).degrees,
            sweepAngleDegrees = theta.degrees,
            forceMoveTo = false,
          )

          lineTo(center + lineTip.rotateBy(theta * (vertex + 1) - roundingThetaHalf))
        }

        close()
      }
    )
}
