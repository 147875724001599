package app.megachat.shared.base

import app.megachat.shared.base.data.BuildConfig
import app.megachat.shared.base.data.PlatformConfig
import org.koin.dsl.module

internal actual val sharedBasePlatformModule = module {

  single<BuildConfig> {
    BuildConfig(
      appVersion = app.megachat.shared.base.BuildConfig.APP_VERSION,
      isDebug = false, // TODO?
    )
  }

  single<PlatformConfig> {
    PlatformConfig.WasmJs(
      userAgent = kotlinx.browser.window.navigator.userAgent,
    )
  }
}
