package app.megachat.shared.base.koin

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancelAndJoin
import org.koin.core.Koin
import org.koin.core.KoinApplication
import org.koin.core.annotation.KoinInternalApi
import org.koin.core.instance.ResolutionContext
import org.koin.core.module.Module
import org.koin.core.qualifier.named
import org.koin.core.scope.Scope
import org.koin.mp.KoinPlatformTools

private val USER_SCOPE_ID = named("_user_")

fun KoinApplication.createUserScope(): Scope =
  koin.createScope(USER_SCOPE_ID.value, USER_SCOPE_ID)

@Suppress("UnusedReceiverParameter")
suspend fun KoinApplication.closeUserScope(userScope: Scope) {
  with (userScope) {
    val job = get<CoroutineScope>().coroutineContext[Job]!!
    close()
    job.cancelAndJoin()
  }
}

fun KoinApplication.createUserScopeEagerInstances(module: Module? = null) {
  userScopeEagerInstances.createEagerInstances(
    resolutionContext = ResolutionContext(
      @OptIn(KoinInternalApi::class) koin.logger,
      koin.userScope,
      Unit::class, // TODO: figure out why this was added in 4.0.0
    ),
    module = module,
  )
}

fun Module.userScope(scopeSet: UserScopeDsl.() -> Unit): Unit =
  scope(USER_SCOPE_ID) {
    UserScopeDsl(
      scopeDSL = this@scope,
      onEagerInstance = { userScopeEagerInstances.add(module, it) },
    )
      .scopeSet()
  }

val Scope.userScope: Scope
  get() = getKoin().userScope

val userScope: Scope
  get() = KoinPlatformTools.defaultContext().get().userScope

val userScopeOrNull: Scope?
  get() = KoinPlatformTools.defaultContext().get().userScopeOrNull

private val Koin.userScope: Scope
  inline get() = getScope(USER_SCOPE_ID.value)

private val Koin.userScopeOrNull: Scope?
  inline get() = getScopeOrNull(USER_SCOPE_ID.value)

private val userScopeEagerInstances = EagerInstances()
