package app.megachat.shared.base.data

import kotlin.jvm.JvmInline

@JvmInline
value class UserTag(val value: String) {

  override fun toString() = "UserTag($value)"

  companion object {
    val EMPTY = UserTag("")

    const val MIN_LENGTH = 4 // reserve shorter tags for future
    const val MAX_CANONICAL_LENGTH = 20

    internal val RESERVED_PREFIXES = setOf(
      "admin",
      "help",
      "official",
      "reserved",
      "test",
    )
  }
}
