package app.megachat.client.ui.design.custom

import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Outline
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import app.megachat.client.ui.design.util.RoundedPolygonShape
import app.megachat.client.ui.design.util.degrees

@Composable
fun rememberCustomShape(customShape: CustomShape?): Shape =
  remember(customShape) {
    when (customShape) {
      is CustomShape.Circle -> CustomShapes.Circle
      is CustomShape.Heart -> CustomShapes.Heart
      is CustomShape.Hexagon35H -> CustomShapes.Hexagon35H
      is CustomShape.Hexagon35V -> CustomShapes.Hexagon35V
      is CustomShape.Octagon40H -> CustomShapes.Octagon40H
      is CustomShape.Square20H -> CustomShapes.Square20H
      is CustomShape.Square20V -> CustomShapes.Square20V
      is CustomShape.Triangle50H -> CustomShapes.Triangle50H
      is CustomShape.Triangle50V -> CustomShapes.Triangle50V
      is CustomShape.Unknown, null -> CustomShapes.Default
    }
  }

object CustomShapes {

  val Circle = RoundedCornerShape(percent = 50) // weird because 50% is fully rounded

  val Default = Circle

  val Square20H = RoundedCornerShape(percent = 10)

  val Square20V = RoundedPolygonShape(
    edgeCount = 4,
    percent = 20f,
    scale = 1.05f,
  )

  val Heart = object : Shape {
    override fun createOutline(
      size: Size,
      layoutDirection: LayoutDirection,
      density: Density,
    ): Outline =
      Outline.Generic(
        Path().apply {
          val w = size.width
          val h = size.height

          val dip = Offset(w / 2, h / 10)
          val tip = Offset(w / 2, h)

          moveTo(tip.x, tip.y)

          cubicTo(
            w / 8f, h * 3 / 4f,
            0f, h * 5 / 8f,
            0f, h * 3 / 8f,
          )

          cubicTo(
            0f, h / 8f,
            w / 4f, -h / 10f,
            dip.x, dip.y,
          )

          cubicTo(
            w * 3 / 4f, -h / 10f,
            w, h / 8f,
            w, h * 3 / 8f,
          )

          cubicTo(
            w, h * 5 / 8f,
            w * 7 / 8f, h * 3 / 4f,
            tip.x, tip.y,
          )

          close()
        }
      )
  }

  val Hexagon35H = RoundedPolygonShape(
    edgeCount = 6,
    percent = 35f,
    scale = 1.05f,
    angle = 30.degrees,
  )

  val Hexagon35V = RoundedPolygonShape(
    edgeCount = 6,
    percent = 35f,
    scale = 1.05f,
  )

  val Octagon40H = RoundedPolygonShape(
    edgeCount = 8,
    percent = 40f,
    scale = 1.08f,
    angle = 22.5f.degrees,
  )

  val Triangle50H = RoundedPolygonShape(
    edgeCount = 3,
    percent = 50f,
    centerY = 0.55f,
    scale = 1.45f,
  )

  val Triangle50V = RoundedPolygonShape(
    edgeCount = 3,
    percent = 50f,
    centerY = 0.45f,
    scale = 1.45f,
    angle = 60.degrees,
  )
}
