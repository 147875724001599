package app.megachat.client.ui.design.boid

import app.megachat.client.ui.design.boid.BoidSimulator.Boid
import app.megachat.client.ui.design.boid.BoidSimulator.Simulation
import kotlinx.collections.immutable.ImmutableMap

internal class MoveToCenterRule<K : Any, B : Boid<K>, S : Simulation<K, B>>(
  private val factor: Float = 1 / 100f,
) : BoidSimulator.Rule<K, B, S> {
  override fun invoke(
    simulation: S,
    others: ImmutableMap<K, B>,
    boid: B,
  ): Vector3d =
    (simulation.bounds.div(2f) - boid.center) * factor
}
