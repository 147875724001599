package app.megachat.client.ui.design.components

import androidx.compose.material3.DividerDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.material3.VerticalDivider as MaterialVerticalDivider

@Composable
fun VerticalDivider(
  modifier: Modifier = Modifier,
  thickness: Dp = DividerDefaults.Thickness,
  color: Color = DividerDefaults.color,
) {
  MaterialVerticalDivider(
    modifier = modifier,
    thickness = thickness,
    color = color,
  )
}
