package app.megachat.shared.base.util

fun String.truncate(maxLength: Int, ellipsize: Boolean = false): String =
  takeIf { length <= maxLength } ?: take(maxLength).runIf(ellipsize) { plus("...") }

fun String.firstVisibleCharOrNull(): String? =
  graphemes().firstOrNull { it.isNotBlank() }

fun String.splitWords(limit: Int = 0): List<String> =
    split("\\s+".toRegex(), limit = limit).filter { it.isNotEmpty() }

fun String.isWellformedEmail(): Boolean =
  EMAIL_ADDRESS.matches(this)

fun String.fromLeetspeak(): String =
  asSequence().map {
    when (it) {
      '0' -> 'o'
      '1' -> 'l'
      '2' -> 'z'
      '3' -> 'e'
      '4' -> 'a'
      '5' -> 's'
      '6' -> 'b'
      '7' -> 't'
      '8' -> 'b'
      '9' -> 'g'
      '@' -> 'a'
      '!' -> 'i'
      '+' -> 't'
      '$' -> 's'
      else -> it
    }
  }
    .joinToString(separator = "")

// OLD: Copied from https://cs.android.com/androidx/platform/frameworks/support/+/androidx-main:core/core/src/main/java/androidx/core/util/PatternsCompat.java;l=382?q=EMAIL_ADDRESS&ss=androidx%2Fplatform%2Fframeworks%2Fsupport
// NEW: Copied from https://stackoverflow.com/a/1373724
private val EMAIL_ADDRESS =
  """
  [a-z0-9!#${'$'}%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#${'$'}%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?
  """.trimIndent()
    .toRegex()

internal expect fun String.graphemes(): Sequence<String>
