@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package megachat.websiteapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val app_logo_transparent_512: DrawableResource by 
      lazy { init_app_logo_transparent_512() }

  public val app_store_button: DrawableResource by 
      lazy { init_app_store_button() }

  public val google_play_button: DrawableResource by 
      lazy { init_google_play_button() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("app_logo_transparent_512", CommonMainDrawable0.app_logo_transparent_512)
  map.put("app_store_button", CommonMainDrawable0.app_store_button)
  map.put("google_play_button", CommonMainDrawable0.google_play_button)
}

internal val Res.drawable.app_logo_transparent_512: DrawableResource
  get() = CommonMainDrawable0.app_logo_transparent_512

private fun init_app_logo_transparent_512(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:app_logo_transparent_512",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/megachat.websiteapp.generated.resources/drawable/app-logo-transparent-512.webp", -1, -1),
    )
)

internal val Res.drawable.app_store_button: DrawableResource
  get() = CommonMainDrawable0.app_store_button

private fun init_app_store_button(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:app_store_button",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/megachat.websiteapp.generated.resources/drawable/app_store_button.webp", -1, -1),
    )
)

internal val Res.drawable.google_play_button: DrawableResource
  get() = CommonMainDrawable0.google_play_button

private fun init_google_play_button(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:google_play_button",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/megachat.websiteapp.generated.resources/drawable/google_play_button.webp", -1, -1),
    )
)
