package app.megachat.client.ui.design.theme

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.drawscope.withTransform

@Composable
fun Modifier.defaultBackground(): Modifier {
  val theme = AppTheme.theme
  return drawBehind {
    withTransform({
      scale(
        scaleX = if (size.height > size.width) 2f else 2.5f * size.width / size.height,
        scaleY = if (size.height > size.width) 1f else 1.5f,
        pivot = Offset(0f, size.height / 2)
      )
    }) {
      drawRect(
        brush = Brush.radialGradient(
          colors = when (theme) {
            AppTheme.Type.Light -> lightGradientColors
            AppTheme.Type.Dark -> darkGradientColors
          },
          center = Offset(0f, size.height),
          radius = size.height,
        ),
      )
    }
  }
}

private val lightGradientColors = listOf(
  Color(0xFF26CBFF), // bottom
  Color(0xFF63FFCD), // top
)

private val darkGradientColors = listOf(
  Color(0xFF0A283F), // bottom
  Color(0xFF16352B), // top
)
