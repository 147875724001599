package app.megachat.client.ui.design.avatar

import app.megachat.client.ui.design.custom.CustomBorder
import app.megachat.client.ui.design.custom.CustomBrush
import app.megachat.client.ui.design.custom.CustomShape

data class AvatarStyle(
  val border: CustomBorder?,
  val brush: CustomBrush?,
  val shape: CustomShape?,
) {

  val isNotEmpty: Boolean
    get() = this != EMPTY

  companion object {
    val EMPTY = AvatarStyle(
      border = null,
      brush = null,
      shape = null,
    )
  }
}
