package app.megachat.website.main

import androidx.compose.runtime.Composable
import app.megachat.client.ui.core.navigation.Presenter
import app.megachat.client.ui.core.navigation.rememberNavigator
import app.megachat.client.ui.design.media.ImageLoaders
import app.megachat.shared.base.data.BuildConfig
import app.megachat.website.config.WebsiteConfig
import app.megachat.website.main.Main.Event
import app.megachat.website.main.Main.State
import app.megachat.website.welcome.WelcomeScreen

internal class MainPresenter(
  private val buildConfig: BuildConfig,
  private val imageLoaders: ImageLoaders,
  private val websiteConfig: WebsiteConfig,
): Presenter<State, Event> {

  @Composable
  override fun present(): State {

    val navigator = rememberNavigator(
      initialRoot = WelcomeScreen,
    )

    return State(
      navigator = navigator,
      imageLoaders = imageLoaders,
      isDebug = buildConfig.isDebug,
      showEnvironmentName = websiteConfig.displayName.takeIf { !websiteConfig.isProduction },
    ) { event ->
      //when (event) {
        // TODO
      //}
    }
  }
}
