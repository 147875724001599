package app.megachat.website.main

import androidx.compose.runtime.Immutable
import app.megachat.client.ui.core.koin.factory
import app.megachat.client.ui.core.navigation.Destination
import app.megachat.client.ui.core.navigation.Navigator
import app.megachat.client.ui.core.navigation.Ui
import app.megachat.client.ui.core.navigation.UiEvent
import app.megachat.client.ui.core.navigation.UiState
import app.megachat.client.ui.design.media.ImageLoaders
import app.megachat.shared.base.koin.rootScope
import app.megachat.shared.base.parcel.Parcelize
import app.megachat.shared.base.util.lazyPub
import org.koin.dsl.module

@Parcelize
data object MainScreen : Destination(
  presentation = Presentation.FullScreen(),
)

internal object Main {

  @Immutable
  data class State(
    val navigator: Navigator,
    val imageLoaders: ImageLoaders,
    val isDebug: Boolean,
    val showEnvironmentName: String?,
    override val send: (Event) -> Unit,
  ) : UiState<Event> {

    companion object {
      val EMPTY by lazyPub {
        State(
          navigator = Navigator.EMPTY,
          imageLoaders = ImageLoaders.EMPTY,
          isDebug = false,
          showEnvironmentName = null,
          send = {},
        )
      }
    }
  }

  sealed interface Event : UiEvent {
    // TODO
  }

  val Module = module {
    factory<MainScreen>(
      scope = { rootScope },
      presenter = { _, _ ->
        MainPresenter(
          buildConfig = get(),
          imageLoaders = get(),
          websiteConfig = get(),
        )
      },
      ui = { _ ->
        Ui<State, Event> { state, modifier ->
          MainContent(state, modifier)
        }
      },
    )
  }
}
