package app.megachat.client.ui.design.util

import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.Brush.Companion.radialGradient
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.drawscope.scale
import androidx.compose.ui.graphics.isSpecified
import app.megachat.shared.base.util.runIf

fun Modifier.ovalShadow(
  color: Color,
) : Modifier =
  runIf(color.isSpecified) {
    drawBehind {
      val scaleX = if (size.width > size.height) 0.8f * size.width / size.height else 0.8f
      val scaleY = if (size.height > size.width) 0.8f * size.height / size.width else 0.8f
      scale(scaleX, scaleY) {
        drawRect(
          radialGradient(
            0f to color.copy(alpha = 0.15f),
            0.5f to color.copy(alpha = 0.15f),
            1f to color.copy(alpha = 0f),
          )
        )
      }
    }
  }
