package app.megachat.client.ui.design.components

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.Snackbar
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import app.megachat.client.ui.design.material.snackbar.SnackbarHost
import app.megachat.client.ui.design.theme.Shapes
import app.megachat.client.ui.design.util.SnackbarState
import app.megachat.client.ui.design.util.rememberSnackbarState
import androidx.compose.material3.Scaffold as MaterialScaffold

@Composable
fun Scaffold(
  modifier: Modifier = Modifier,
  topBar: @Composable () -> Unit = {},
  bottomBar: @Composable () -> Unit = {},
  snackbar: SnackbarState = rememberSnackbarState(),
  content: @Composable (PaddingValues) -> Unit,
) {
  MaterialScaffold(
    modifier = modifier,
    topBar = topBar,
    bottomBar = bottomBar,
    content = content,
    containerColor = Color.Unspecified,
    snackbarHost = {
      SnackbarHost(snackbar.snackbarHostState) { snackbarData ->
        Box(
          modifier = Modifier.fillMaxWidth(),
          contentAlignment = Alignment.Center,
        ) {
          Snackbar(
            modifier = Modifier.widthIn(max = 360.dp),
            snackbarData = snackbarData,
            shape = Shapes.snackbar,
            containerColor = snackbarData.style.backgroundColor,
            contentColor = snackbarData.style.contentColor,
          )
        }
      }
    },
  )
}
