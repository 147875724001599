package app.megachat.shared.base.koin

import org.koin.core.annotation.KoinInternalApi
import org.koin.core.instance.InstanceFactory
import org.koin.core.instance.ResolutionContext
import org.koin.core.module.Module

internal class EagerInstances {

  fun add(module: Module, factory: InstanceFactory<*>) {
    factoriesByModuleId.getOrPut(module.id) { mutableListOf() }.add(factory)
  }

  fun createEagerInstances(resolutionContext: ResolutionContext, module: Module?) {
    val processed = mutableSetOf<String>()
    var toProcess = module.allModuleIds()

    // Iterate, because new module(s) could be added by eager instances:
    while (toProcess.isNotEmpty()) {
      toProcess.forEach { moduleId ->
        factoriesByModuleId[moduleId]?.onEach { it.get(resolutionContext) }
      }
      processed.addAll(toProcess)
      toProcess = module.allModuleIds() - processed
    }
  }

  private fun Module?.allModuleIds(): Set<String> =
    (this?.all()?.map(Module::id) ?: factoriesByModuleId.keys).toSet()

  private val factoriesByModuleId = mutableMapOf<String, MutableList<InstanceFactory<*>>>()

  companion object {

    @OptIn(KoinInternalApi::class)
    fun Module.all(): List<Module> =
      listOf(this) + includedModules.flatMap { it.all() }
  }
}
