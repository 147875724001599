package app.megachat.website

import androidx.compose.runtime.Composable
import app.megachat.client.ui.core.navigation.Content
import app.megachat.website.main.MainScreen

@Composable
internal fun MainContent() {
  Content(
    destination = MainScreen,
  )
}
