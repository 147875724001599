package app.megachat.client.ui.design.theme

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.coerceAtMost
import androidx.compose.ui.unit.dp

object Dimensions {

  val Gap200 = 32.dp
  val Gap150 = 24.dp
  val Gap100 = 16.dp // The normal gap, 100%
  val Gap75 = 12.dp
  val Gap50 = 8.dp
  val Gap25 = 4.dp

  val MaxColumnWidth = 360.dp // NOTE: does not include padding!

  val MaxContentWidth = 480.dp

  val MaxUserProfileSize = 480.dp

  val MinTextButtonWidth = 120.dp

  val MinPickerColumnWidth = 140.dp

  val ButtonHeight = 48.dp
  val SearchBarHeight = 56.dp
  val TopAppBarHeight = 64.dp

  // Prevent narrow side gaps:
  fun maxSheetWidth(screenWidth: Dp): Dp =
    if (screenWidth <= 480.dp) screenWidth
    else (screenWidth - 80.dp).coerceAtMost(640.dp)
}
