package app.megachat.website.browser

internal class BrowserImpl: Browser {

  override val baseUrl: String
    get() = kotlinx.browser.document.baseURI

  override fun openUrl(url: String) {
    kotlinx.browser.window.open(url = url)
  }
}
