package app.megachat.client.ui.design.draw

import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.geometry.center
import androidx.compose.ui.geometry.isSpecified
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shader
import androidx.compose.ui.graphics.ShaderBrush

@Stable
fun Brush.Companion.angledSweepGradient(
  vararg colorStops: Pair<Float, Color>,
  center: Offset = Offset.Unspecified,
  startAngle: Float = 0f,
): Brush =
  AngledSweepGradient(
    colors = List(colorStops.size) { i -> colorStops[i].second },
    stops = List(colorStops.size) { i -> colorStops[i].first },
    center = center,
    startAngle = startAngle,
  )

@Stable
fun Brush.Companion.angledSweepGradient(
  colors: List<Color>,
  center: Offset = Offset.Unspecified,
  startAngle: Float = 0f,
): Brush =
  AngledSweepGradient(
    colors = colors,
    stops = null,
    center = center,
    startAngle = startAngle,
  )

@Immutable
private class AngledSweepGradient(
  private val center: Offset,
  private val colors: List<Color>,
  private val stops: List<Float>? = null,
  private val startAngle: Float,
) : ShaderBrush() {

  init {
    validateColorStops(colors, stops)
  }

  @Suppress("cast_never_succeeds")
  override fun createShader(size: Size): Shader {

    return angledSweepGradientShader(
      if (center.isSpecified) Offset(
        if (center.x == Float.POSITIVE_INFINITY) size.width else center.x,
        if (center.y == Float.POSITIVE_INFINITY) size.height else center.y,
      ) else size.center,
      colors,
      stops,
      startAngle
    ) as Shader
  }

  override fun equals(other: Any?): Boolean {
    if (this === other) return true
    if (other !is AngledSweepGradient) return false

    if (center != other.center) return false
    if (colors != other.colors) return false
    if (stops != other.stops) return false
    if (startAngle != other.startAngle) return false

    return true
  }

  override fun hashCode(): Int {
    var result = center.hashCode()
    result = 31 * result + colors.hashCode()
    result = 31 * result + (stops?.hashCode() ?: 0)
    result = 31 * result + startAngle.hashCode()
    return result
  }

  override fun toString(): String {
    val centerValue = if (center.isSpecified) "center=$center, " else ""
    return "AngledSweepGradient(" +
        centerValue +
        "colors=$colors, stops=$stops, startAngle=$startAngle)"
  }
}

private fun validateColorStops(colors: List<Color>, colorStops: List<Float>?) {
  if (colorStops == null) {
    if (colors.size < 2) {
      throw IllegalArgumentException(
        "colors must have length of at least 2 if colorStops " +
            "is omitted."
      )
    }
  } else if (colors.size != colorStops.size) {
    throw IllegalArgumentException(
      "colors and colorStops arguments must have" +
          " equal length."
    )
  }
}

@Suppress("expect_actual_classifiers_are_in_beta_warning")
expect class PlatformShader

internal expect fun angledSweepGradientShader(
  center: Offset,
  colors: List<Color>,
  colorStops: List<Float>?,
  startAngle: Float,
): PlatformShader

