package app.megachat.client.ui.design.boid

import androidx.compose.runtime.State
import app.megachat.client.ui.design.boid.BoidSimulator.Boid
import app.megachat.client.ui.design.boid.BoidSimulator.Simulation
import kotlinx.collections.immutable.ImmutableMap

internal class GravityRule<K : Any, B : Boid<K>, S : Simulation<K, B>>(
  private val gravity: State<Vector3d>,
) : BoidSimulator.Rule<K, B, S> {
  override fun invoke(
    simulation: S,
    others: ImmutableMap<K, B>,
    boid: B,
  ): Vector3d =
    gravity.value.run {
      copy(
        // We really want X tilt to be noticeable
        x = -x * 8f * boid.weight,
        // Unlike X and Z, Y is in the _right_ direction
        y = y * 4f * boid.weight,
        z = -z * 2f,
      )
    }
}
