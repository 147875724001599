package app.megachat.shared.base.util

inline fun <T> lazyPub(noinline initializer: () -> T): Lazy<T> =
  lazy(LazyThreadSafetyMode.PUBLICATION, initializer)

inline fun <T> T.applyIf(predicate: Boolean, block: T.() -> Unit): T =
  apply { if (predicate) block() }

inline fun <T> T.runIf(predicate: Boolean, block: T.() -> T): T =
  run { if (predicate) block() else this }

inline fun <T, E : Any> T.runIfNotNull(value: E?, block: T.(E) -> T): T =
  run { if (value != null) block(value) else this }

inline fun <T> T.alsoIf(predicate: Boolean, block: (T) -> Unit): T =
  also { if (predicate) block(it) }

inline fun Boolean.alsoIfFalse(block: () -> Unit): Boolean =
  also { if (!this) block() }

inline fun Boolean.alsoIfTrue(block: () -> Unit): Boolean =
  also { if (this) block() }

inline fun <T> T.alsoIfNull(block: () -> Unit): T =
  also { if (this == null) block() }
