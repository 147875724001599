package app.megachat.website.coil

import app.megachat.client.ui.design.media.ImageLoaders
import coil3.ImageLoader
import coil3.PlatformContext
import org.koin.dsl.module

internal actual val platformCoilModule = module {

  single<ImageLoaders> {
    val default = ImageLoader.Builder(context = PlatformContext.INSTANCE)
      .build()

    object : ImageLoaders {
      override val default = default
    }
  }
}
