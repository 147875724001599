package app.megachat.website.coil

import org.koin.core.module.Module
import org.koin.dsl.module

internal val coilModule = module {
  includes(
    platformCoilModule,
  )
}

internal expect val platformCoilModule: Module
