package app.megachat.shared.base.log

import org.koin.core.logger.Level
import org.koin.core.logger.Level.DEBUG
import org.koin.core.logger.Level.ERROR
import org.koin.core.logger.Level.INFO
import org.koin.core.logger.Level.NONE
import org.koin.core.logger.Level.WARNING

object KoinLogger : org.koin.core.logger.Logger(level = INFO) {

  override fun display(
    level: Level,
    msg: String,
  ) {
    when (level) {
      DEBUG -> log.d { msg }

      INFO -> log.i { msg }

      WARNING -> log.w { msg }

      ERROR -> log.e { msg }

      NONE -> Unit
    }
  }

  private val log = Logger("Koin")
}
