package app.megachat.client.ui.design.media

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import app.megachat.shared.base.data.HttpsUrl
import coil3.compose.AsyncImage
import coil3.compose.AsyncImagePainter
import coil3.toUri

@Composable
fun Image(
  url: HttpsUrl?,
  contentDescription: String? = null,
  modifier: Modifier = Modifier,
  placeholder: Painter? = null,
  onSuccess: (() -> Unit)? = null,
  onFailure: (() -> Unit)? = null,
  contentScale: ContentScale = ContentScale.Fit,
) =
  AsyncImage(
    model = url?.value?.toUri(),
    contentDescription = contentDescription,
    imageLoader = LocalImageLoaders.current.default,
    modifier = modifier,
    placeholder = placeholder,
    onSuccess = if (onSuccess != null) {
      { _: AsyncImagePainter.State.Success -> onSuccess() }
    } else null,
    onError = if (onFailure != null) {
      { _: AsyncImagePainter.State.Error -> onFailure() }
    } else null,
    contentScale = contentScale,
  )
