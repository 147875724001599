package app.megachat.client.ui.design.material.snackbar

import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.scaleIn
import androidx.compose.animation.scaleOut
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.material3.SnackbarDuration
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import app.megachat.client.ui.design.material.snackbar.SnackbarHostState.SnackbarData
import app.megachat.client.ui.design.util.AnimatedVisibility
import kotlinx.coroutines.delay

//
// Copied from material-1.6.11 and modified, adding style to data, and changing animation
//
@Composable
internal fun SnackbarHost(
  hostState: SnackbarHostState,
  snackbar: @Composable (SnackbarData) -> Unit,
) {
  val currentSnackbarData = hostState.currentSnackbarData
  LaunchedEffect(currentSnackbarData) {
    if (currentSnackbarData != null) {
      delay(
        when (currentSnackbarData.visuals.duration) {
          SnackbarDuration.Indefinite -> Long.MAX_VALUE
          SnackbarDuration.Long -> 10000L
          SnackbarDuration.Short -> 4000L
        }
      )
      currentSnackbarData.dismiss()
    }
  }
  AnimatedVisibility(
    visible = currentSnackbarData != null,
    state = currentSnackbarData,
    enter = slideInVertically { it } + fadeIn() + scaleIn(initialScale = 0.5f),
    exit = slideOutVertically { it } + fadeOut() + scaleOut(targetScale = 0.5f),
  ) {
    snackbar(it)
  }
}
