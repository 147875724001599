package app.megachat.client.ui.design.draw

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shader
import androidx.compose.ui.graphics.toArgb
import org.jetbrains.skia.FilterTileMode
import org.jetbrains.skia.GradientStyle

internal actual fun angledSweepGradientShader(
  center: Offset,
  colors: List<Color>,
  colorStops: List<Float>?,
  startAngle: Float,
): Shader =
  Shader.makeSweepGradient(
    x = center.x,
    y = center.y,
    startAngle = 0f + startAngle,
    endAngle = 360f + startAngle,
    colors = colors.toIntArray(),
    positions = colorStops?.toFloatArray(),
    style = GradientStyle.DEFAULT.withTileMode(FilterTileMode.REPEAT)
  )

private fun List<Color>.toIntArray(): IntArray =
  IntArray(size) { i -> this[i].toArgb() }

@Suppress("expect_actual_classifiers_are_in_beta_warning")
internal actual typealias PlatformShader = org.jetbrains.skia.Shader
