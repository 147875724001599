package app.megachat.client.ui.core.navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import app.megachat.client.ui.core.circuit.NavDecoration
import app.megachat.shared.base.koin.rootScope
import com.slack.circuit.foundation.CircuitContent
import com.slack.circuit.foundation.NavigableCircuitContent

@Composable
fun Content(
  destination: Destination,
  modifier: Modifier = Modifier,
) {
  CircuitContent(
    screen = destination,
    circuit = rootScope.get(),
    modifier = modifier,
  )
}

@Composable
fun Content(
  navigator: Navigator,
  modifier: Modifier = Modifier,
) {
  NavigableCircuitContent(
    backStack = navigator.circuitBackstack,
    navigator = navigator.circuitNavigator,
    circuit = rootScope.get(),
    modifier = modifier,
    decoration = remember(navigator) {
      NavDecoration(pop = navigator::pop)
    }
  )
}
