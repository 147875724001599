package app.megachat.client.ui.design.custom

import androidx.compose.runtime.Immutable

@Immutable
sealed interface CustomShape {
  val key: String
  val pickdata: Pickdata?

  data class Circle(
    override val pickdata: Pickdata? = null,
  ) : CustomShape {
    override val key = "Circle"
  }

  data class Heart(
    override val pickdata: Pickdata? = null,
  ) : CustomShape {
    override val key = "Heart"
  }

  data class Hexagon35H(
    override val pickdata: Pickdata? = null,
  ) : CustomShape {
    override val key = "Hexagon35H"
  }

  data class Hexagon35V(
    override val pickdata: Pickdata? = null,
  ) : CustomShape {
    override val key = "Hexagon35V"
  }

  data class Octagon40H(
    override val pickdata: Pickdata? = null,
  ) : CustomShape {
    override val key = "Octagon40H"
  }

  data class Square20H(
    override val pickdata: Pickdata? = null,
  ) : CustomShape {
    override val key = "Square20H"
  }

  data class Square20V(
    override val pickdata: Pickdata? = null,
  ) : CustomShape {
    override val key = "Square20V"
  }

  data class Triangle50H(
    override val pickdata: Pickdata? = null,
  ) : CustomShape {
    override val key = "Triangle50H"
  }

  data class Triangle50V(
    override val pickdata: Pickdata? = null,
  ) : CustomShape {
    override val key = "Triangle50V"
  }

  data object Unknown : CustomShape {
    override val key = "Unknown"
    override val pickdata: Pickdata? = null
  }

  companion object
}
