package app.megachat.client.ui.design.boid

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import app.megachat.client.ui.design.boid.BoidSimulator.Boid
import app.megachat.client.ui.design.boid.BoidSimulator.Simulation
import kotlinx.collections.immutable.ImmutableMap

internal class BoundsRule<K : Any, B : Boid<K>, S : Simulation<K, B>>(
  private val factor: Float = 1f,
) : BoidSimulator.Rule<K, B, S> {
  override fun invoke(
    simulation: S,
    others: ImmutableMap<K, B>,
    boid: B,
  ): Vector3d {
    val min = Vector3d(boid.radius, boid.radius, boid.radius)
    val max = simulation.bounds - min
    return Vector3d(
      x = forAxis(boid.center.x, min.x, max.x),
      y = forAxis(boid.center.y, min.y, max.y),
      z = forAxis(boid.center.z, min.z, max.z),
    ) * factor
  }

  private fun forAxis(value: Dp, min: Dp, max: Dp): Dp =
    when {
      value < min -> min - value
      value > max -> max - value
      else -> 0.dp
    }
}
