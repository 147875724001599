package app.megachat.client.ui.design.components.sheet

import androidx.compose.animation.core.exponentialDecay
import androidx.compose.animation.core.spring
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.gestures.AnchoredDraggableState
import androidx.compose.foundation.gestures.animateTo
import androidx.compose.foundation.gestures.snapTo
import androidx.compose.runtime.Composable
import androidx.compose.runtime.key
import androidx.compose.runtime.saveable.Saver
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.dp
import app.megachat.client.ui.design.components.sheet.SheetState.Companion.Saver
import app.megachat.client.ui.design.components.sheet.SheetVisibility.Expanded
import app.megachat.client.ui.design.components.sheet.SheetVisibility.Hidden
import kotlinx.coroutines.CancellationException

@Composable
fun rememberSheetState(
  initialVisibility: SheetVisibility = Hidden,
): SheetState {
  val density = LocalDensity.current
  return key(initialVisibility) {
    rememberSaveable(
      initialVisibility,
      saver = Saver(
        density = density,
      )
    ) {
      SheetState(
        density = density,
        initialVisibility = initialVisibility,
      )
    }
  }
}

@OptIn(ExperimentalFoundationApi::class)
class SheetState(
  density: Density,
  initialVisibility: SheetVisibility,
) {

  internal val swipeableState = AnchoredDraggableState(
    initialValue = initialVisibility,
    positionalThreshold = { it * 0.4f },
    velocityThreshold = { with(density) { 250.dp.toPx() } },
    snapAnimationSpec = spring(),
    decayAnimationSpec = exponentialDecay(),
    confirmValueChange = { true },
  )

  val currentVisibility: SheetVisibility
    get() = swipeableState.currentValue

  val targetVisibility: SheetVisibility
    get() = swipeableState.targetValue

  /**
   * Whether the bottom sheet is fully visible, i.e. not dragging or animating away
   */
  val isFullyVisible: Boolean
    get() = currentVisibility == Expanded && targetVisibility == Expanded

  /**
   * Show the bottom sheet with animation and suspend until it's shown. If the sheet is taller
   * than 50% of the parent's height, the bottom sheet will be half expanded. Otherwise it will be
   * fully expanded.
   *
   * @throws [CancellationException] if the animation is interrupted
   */
  suspend fun show() {
    animateTo(Expanded)
  }

  /**
   * Fully expand the bottom sheet with animation and suspend until it if fully expanded or
   * animation has been cancelled.
   * *
   * @throws [CancellationException] if the animation is interrupted
   */
  internal suspend fun expand() {
    if (!swipeableState.anchors.hasAnchorFor(Expanded)) {
      return
    }
    animateTo(Expanded)
  }

  /**
   * Hide the bottom sheet with animation and suspend until it if fully hidden or animation has
   * been cancelled.
   *
   * @throws [CancellationException] if the animation is interrupted
   */
  suspend fun hide() {
    animateTo(Hidden)
  }

  private suspend fun animateTo(
    target: SheetVisibility,
  ) {
    swipeableState.animateTo(target)
  }

  internal suspend fun snapTo(target: SheetVisibility) {
    swipeableState.snapTo(target)
  }

  internal val lastVelocity: Float
    get() = swipeableState.lastVelocity

  val isAnimationRunning: Boolean
    get() = swipeableState.isAnimationRunning

  companion object {
    fun Saver(
      density: Density,
    ): Saver<SheetState, *> = Saver(
      save = { it.currentVisibility },
      restore = {
        SheetState(
          density = density,
          initialVisibility = it,
        )
      }
    )
  }
}
