package app.megachat.client.ui.design.theme

import androidx.compose.foundation.shape.GenericShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Matrix
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.copy
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import app.megachat.shared.base.util.lazyPub

object Shapes {
  val pill = RoundedCornerShape(percent = 50)

  @Composable
  fun chatBubble(atStart: Boolean): Shape =
    if (atStart) {
      RoundedCornerShape(topStart = 12.dp, topEnd = 12.dp, bottomEnd = 12.dp, bottomStart = 0.dp)
    } else {
      RoundedCornerShape(topStart = 12.dp, topEnd = 12.dp, bottomEnd = 0.dp, bottomStart = 12.dp)
    }

  val chatInputFieldShape = RoundedCornerShape(26.dp)

  val searchFieldPill = RoundedCornerShape(16.dp, 16.dp, 0.dp, 0.dp)

  @Composable
  fun bottomRect(height: Dp): Shape {
    val heightPx = with(LocalDensity.current) { height.toPx() }
    return GenericShape { size, _ ->
      moveTo(0f, size.height)
      lineTo(size.width, size.height)
      lineTo(size.width, size.height - heightPx)
      lineTo(0f, size.height - heightPx)
    }
  }

  @Composable
  fun startWedge(minWidth: Dp, maxWidth: Dp): Shape {
    val minWidthPx = with(LocalDensity.current) { minWidth.toPx() }
    val maxWidthPx = with(LocalDensity.current) { maxWidth.toPx() }
    return GenericShape { size, _ ->
      moveTo(0f, 0f)
      lineTo(0f, size.height)
      lineTo(minWidthPx, size.height)
      lineTo(maxWidthPx, size.height / 2)
      lineTo(minWidthPx, 0f)
      close()
    }
  }

  val snackbar = RoundedCornerShape(
    topStart = 12.dp,
    topEnd = 12.dp,
    bottomEnd = 12.dp,
    bottomStart = 12.dp,
  )

  val bottomSheet = RoundedCornerShape(
    topStart = 24.dp,
    topEnd = 24.dp,
    bottomEnd = 0.dp,
    bottomStart = 0.dp,
  )

  val popup = RoundedCornerShape(32.dp)

  val sheet = RoundedCornerShape(24.dp)

  val backDrop = GenericShape { size, _ ->
    addPath(
      backDropPath
        .copy()
        .apply { transform(Matrix().apply { scale(x = size.width / 16, y = size.height / 12) }) }
    )
  }

  val checkShadow = GenericShape { size, _ ->
    addPath(
      checkPath
        .copy()
        .apply { transform(Matrix().apply { scale(x = size.width / 24, y = size.height / 24) }) }
    )
  }

  val swirl = GenericShape { size, _ ->
    addPath(
      swirlPath
        .copy()
        .apply { transform(Matrix().apply { scale(x = size.width / 24, y = size.height / 24) }) }
    )
  }

  val ribbonLoopA = GenericShape { size, _ ->
    addPath(
      ribbonLoopAPath
        .copy()
        .apply { transform(Matrix().apply { scale(x = size.width / 16, y = size.height / 12) }) }
    )
  }

  val ribbonLoopB = GenericShape { size, _ ->
    addPath(
      ribbonLoopBPath
        .copy()
        .apply { transform(Matrix().apply { scale(x = size.width / 16, y = size.height / 12) }) }
    )
  }

  val ribbonLoop1 = GenericShape { size, _ ->
    addPath(
      ribbonLoopPath1
        .copy()
        .apply { transform(Matrix().apply { scale(x = size.width / 16, y = size.height / 24) }) }
    )
  }

  val ribbonLoop2 = GenericShape { size, _ ->
    addPath(
      ribbonLoopPath2
        .copy()
        .apply { transform(Matrix().apply { scale(x = size.width / 16, y = size.height / 24) }) }
    )
  }

  private val backDropPath by lazyPub {
    Path().apply {
      moveTo(10f, 20f)
      lineTo(8f, 18f)
      lineTo(6f, 20f)
      lineTo(4f, 18f)
      lineTo(2f, 20f)
      lineTo(0f, 18f)
      lineTo(0f, 0f)
      lineTo(16f, 0f)
      lineTo(16f, 18f)
      lineTo(14f, 20f)
      lineTo(12f, 18f)
      lineTo(10f, 20f)
      close()
    }
  }

  private val checkPath by lazyPub {
    Path().apply {
      moveTo(9.0f, 16.17f)
      lineTo(4.83f, 12.0f)
      relativeLineTo(-1.42f, 1.41f)
      lineTo(9.0f, 19.0f)
      lineTo(21.0f, 7.0f)
      relativeLineTo(-1.41f, -1.41f)
      close()
    }
  }

  private val swirlPath by lazyPub {
    Path().apply {
      moveTo(6.812f, 1.113f)
      cubicTo(9.581f, -0.218f, 13.232f, -0.256f, 15.273f, 0.478f)
      cubicTo(17.167f, 1.158f, 18.545f, 4.307f, 18f, 5.401f)
      cubicTo(17.455f, 6.495f, 15.661f, 3.92f, 12f, 4.307f)
      cubicTo(1.636f, 5.401f, 2.047f, 16.578f, 8.727f, 19.624f)
      cubicTo(15.408f, 22.669f, 20.698f, 18.671f, 21.818f, 13.606f)
      cubicTo(23.513f, 5.948f, 18.028f, 1.559f, 18.028f, 1.559f)
      cubicTo(20.683f, 3.106f, 22.646f, 5.615f, 23.513f, 8.57f)
      cubicTo(24.379f, 11.525f, 24.083f, 14.701f, 22.685f, 17.443f)
      cubicTo(21.287f, 20.186f, 18.894f, 22.286f, 15.998f, 23.312f)
      cubicTo(13.103f, 24.339f, 9.925f, 24.212f, 7.119f, 22.96f)
      cubicTo(4.314f, 21.707f, 2.094f, 19.423f, 0.917f, 16.578f)
      cubicTo(-0.261f, 13.734f, -0.305f, 10.545f, 0.791f, 7.668f)
      cubicTo(1.888f, 4.791f, 4.043f, 2.445f, 6.812f, 1.113f)
      close()
    }
  }

  private val ribbonLoopAPath by lazyPub {
    Path().apply {
      moveTo(10f, -8f)
      cubicTo(13f, -8f, 9f, -8f, 6f, -8f)
      cubicTo(3f, -8f, 1.524f, -1.401f, 2.5f, 3f)
      cubicTo(3.734f, 8.568f, 9.5f, 12f, 14f, 12f)
      cubicTo(14f, 12f, 22f, 12f, 18f, 12f)
      cubicTo(14f, 12f, 8.126f, 8.063f, 7f, 2.5f)
      cubicTo(6.154f, -1.68f, 7f, -8f, 10f, -8f)
      close()
    }
  }

  private val ribbonLoopBPath by lazyPub {
    Path().apply {
      moveTo(6f, -8f)
      cubicTo(2f, -8f, 7f, -8f, 10f, -8f)
      cubicTo(13f, -8f, 14.503f, -0.591f, 13f, 4f)
      cubicTo(11.347f, 9.048f, 6f, 12f, 2f, 12f)
      cubicTo(-2f, 12f, -6f, 12f, -2f, 12f)
      cubicTo(2f, 12f, 8.848f, 8.743f, 10f, 3f)
      cubicTo(10.899f, -1.482f, 10f, -8f, 6f, -8f)
      close()
    }
  }

  private val ribbonLoopPath1 by lazyPub {
    Path().apply {
      moveTo(10f, 0f)
      cubicTo(13.5f, 0f, 10f, 0f, 6f, 0f)
      cubicTo(2f, 0f, 0.405f, 9.361f, 2.5f, 15f)
      cubicTo(4.486f, 20.346f, 9.5f, 24f, 14f, 24f)
      cubicTo(14f, 24f, 22f, 24f, 18f, 24f)
      cubicTo(14f, 24f, 8.854f, 19.865f, 7f, 14.5f)
      cubicTo(5.112f, 9.035f, 6.5f, -0f, 10f, 0f)
      close()
    }
  }

  private val ribbonLoopPath2 by lazyPub {
    Path().apply {
      moveTo(6f, 0f)
      cubicTo(2f, 0f, 7f, 0f, 10f, 0f)
      cubicTo(13f, -0f, 15.678f, 10.234f, 13f, 16f)
      cubicTo(10.762f, 20.817f, 6f, 24f, 2f, 24f)
      cubicTo(-2f, 24f, -6f, 24f, -2f, 24f)
      cubicTo(2f, 24f, 8.019f, 20.513f, 10f, 15f)
      cubicTo(12.05f, 9.295f, 10f, 0f, 6f, 0f)
      close()
    }
  }
}
