package app.megachat.client.ui.design.util

import androidx.compose.runtime.Stable
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.dp
import kotlin.math.cos
import kotlin.math.sin
import kotlin.math.sqrt

@Stable
operator fun DpOffset.times(value: Float): DpOffset =
  DpOffset(x = x * value, y = y * value)

@Stable
operator fun DpOffset.div(value: Float): DpOffset =
  DpOffset(x = x / value, y = y / value)

@Stable
operator fun DpOffset.div(value: Dp): Offset =
  Offset(x = x / value, y = y / value)

@Stable
val DpOffset.distance: Dp
  inline get() = sqrt(x.value * x.value + y.value * y.value).dp

@Stable
fun DpOffset(angle: Angle, distance: Dp): DpOffset =
  DpOffset(x = distance, y = 0.dp).rotateBy(angle)

@Stable
fun DpOffset.rotateBy(angle: Angle): DpOffset =
  angle.radians.let { radians ->
    val cos = cos(radians)
    val sin = sin(radians)
    DpOffset(
      x = (x * cos - y * sin),
      y = (x * sin + y * cos)
    )
  }
