package app.megachat.shared.base.koin

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancelAndJoin
import org.koin.core.Koin
import org.koin.core.KoinApplication
import org.koin.core.annotation.KoinInternalApi
import org.koin.core.instance.ResolutionContext
import org.koin.core.module.Module
import org.koin.core.qualifier.named
import org.koin.core.scope.Scope
import org.koin.mp.KoinPlatformTools

private val AUTH_SCOPE_ID = named("_auth_")

fun KoinApplication.createAuthScope(): Scope =
  koin.createScope(AUTH_SCOPE_ID.value, AUTH_SCOPE_ID)

@Suppress("UnusedReceiverParameter")
suspend fun KoinApplication.closeAuthScope(authScope: Scope) {
  with (authScope) {
    val job = get<CoroutineScope>().coroutineContext[Job]!!
    close()
    job.cancelAndJoin()
  }
}

fun KoinApplication.createAuthScopeEagerInstances(module: Module? = null) {
  authScopeEagerInstances.createEagerInstances(
    resolutionContext = ResolutionContext(
      @OptIn(KoinInternalApi::class) koin.logger,
      koin.authScope,
      Unit::class, // TODO: figure out why this was added in 4.0.0
    ),
    module = module,
  )
}

fun Module.authScope(scopeSet: AuthScopeDsl.() -> Unit): Unit =
  scope(AUTH_SCOPE_ID) {
    AuthScopeDsl(
      scopeDSL = this@scope,
      onEagerInstance = { authScopeEagerInstances.add(module, it) },
    )
      .scopeSet()
  }

val Scope.authScope: Scope
  get() = getKoin().authScope

val authScope: Scope
  get() = KoinPlatformTools.defaultContext().get().authScope

val authScopeOrNull: Scope?
  get() = KoinPlatformTools.defaultContext().get().authScopeOrNull

private val Koin.authScope: Scope
  inline get() = getScope(AUTH_SCOPE_ID.value)

private val Koin.authScopeOrNull: Scope?
  inline get() = getScopeOrNull(AUTH_SCOPE_ID.value)

private val authScopeEagerInstances = EagerInstances()
