package app.megachat.client.ui.core

import app.megachat.client.ui.core.circuit.circuitModule
import app.megachat.client.ui.design.ClientUiDesignModule
import org.koin.dsl.module

val ClientUiCoreModule = module {
  includes(
    ClientUiDesignModule,
    circuitModule,
  )
}
