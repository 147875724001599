package app.megachat.client.ui.design.emoji

import app.megachat.client.ui.design.emoji.EmojiRepository.ClientEmojis.EmojiData
import app.megachat.shared.base.util.getCurrentLanguageCode
import app.megachat.shared.base.util.mapState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import megachat.client.ui.design.generated.resources.Res
import okio.internal.commonToUtf8String
import org.jetbrains.compose.resources.ExperimentalResourceApi

class EmojiRepository internal constructor(
  private val coroutineScope: CoroutineScope,
  private val json: Json,
) {

  fun categories(): StateFlow<Map<String, Map<String, List<EmojiData>>>?> =
    emojiData.mapState { it?.emojis }

  fun matches(emoji: String, term: String): Boolean =
    emoji.contains(term) ||
        emojiIndex.value?.get(emoji)?.let { (category, subcategory, emoji) ->
          category.contains(term, ignoreCase = true) ||
              subcategory.contains(term, ignoreCase = true) ||
              emoji.name.contains(term, ignoreCase = true) ||
              emoji.translations?.get(languageCode)?.any { it.contains(term, ignoreCase = true) } == true
        } == true

  /** Returns [category, subcategory, name]. */
  fun identify(emoji: String): List<String>? =
    emojiIndex.value?.get(emoji)?.let {
      listOf(it.first, it.second, it.third.name)
    }

  // Same as inside of [buildSrc/src/main/kotlin/GenerateClientEmojisTask]
  @Serializable
  data class ClientEmojis(
    @SerialName("emojis") val emojis: Map<String, Map<String, List<EmojiData>>>,
  ) {
    @Serializable
    data class EmojiData(
      @SerialName("e") val emoji: String,
      @SerialName("n") val name: String,
      @SerialName("t") val translations: Map<String, List<String>>? = null,
    )
  }

  private val emojiData: MutableStateFlow<ClientEmojis?> = MutableStateFlow(null)

  // Maps emoji value to category, subcategory, and data:
  private val emojiIndex: StateFlow<Map<String, Triple<String, String, EmojiData>>?> =
    emojiData.mapState {
      it?.emojis?.let { categories ->
        categories.flatMap { (category, subcategories) ->
          subcategories.flatMap { (subcategory, emojis) ->
            emojis.map { Triple(category, subcategory, it) }
          }
        }.associateBy { it.third.emoji }
      }
    }

  private val languageCode = getCurrentLanguageCode().take(2)

  init {
    @OptIn(ExperimentalResourceApi::class)
    coroutineScope.launch {
      emojiData.value = json.decodeFromString<ClientEmojis>(
        Res.readBytes("files/emoji-client.json").commonToUtf8String()
      )
    }
  }
}
