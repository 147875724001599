package app.megachat.shared.base.data

import app.megachat.shared.base.parcel.Parcelable
import app.megachat.shared.base.parcel.Parcelize
import kotlin.jvm.JvmInline

@Parcelize
@JvmInline
value class HttpsUrl(val value: String) : Parcelable {

  init {
    require(value.startsWith("https://")) { "Invalid HttpsUrl" }
  }

  companion object {
    val EMPTY = HttpsUrl("https://")
  }
}
