package app.megachat.client.ui.core.circuit

import com.slack.circuit.foundation.Circuit
import org.koin.dsl.module

internal val circuitModule = module {

  single {
    Circuit.Builder()
      .addPresenterFactory(KoinPresenterFactory())
      .addUiFactory(KoinUiFactory())
      .setDefaultNavDecoration(OpaqueNavDecoration())
      .build()
  }
}
