package app.megachat.client.ui.core.circuit

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.ExperimentalAnimationApi
import androidx.compose.animation.animateColor
import androidx.compose.animation.core.MutableTransitionState
import androidx.compose.animation.core.tween
import androidx.compose.animation.core.updateTransition
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.scaleIn
import androidx.compose.animation.scaleOut
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.key
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.snapshotFlow
import androidx.compose.runtime.withFrameMillis
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.TransformOrigin
import androidx.compose.ui.input.pointer.pointerInput
import app.megachat.client.ui.core.navigation.Destination
import app.megachat.client.ui.core.navigation.Destination.Presentation
import app.megachat.client.ui.core.util.tapToDismissKeyboard
import app.megachat.client.ui.design.components.sheet.SheetLayout
import app.megachat.client.ui.design.components.sheet.SheetOrigin
import app.megachat.client.ui.design.components.sheet.SheetVisibility
import app.megachat.client.ui.design.components.sheet.rememberSheetState
import app.megachat.client.ui.design.theme.AppTheme
import app.megachat.shared.base.util.runIf
import com.slack.circuit.foundation.RecordContentProvider
import com.slack.circuit.foundation.internal.BackHandler
import kotlinx.collections.immutable.ImmutableList
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.firstOrNull
import kotlinx.coroutines.launch
import com.slack.circuit.backstack.NavDecoration as CircuitNavDecoration

internal class TranslucentNavDecoration(
  val onHidden: () -> Unit,
) : CircuitNavDecoration {

  @Composable
  override fun <T> DecoratedContent(
    args: ImmutableList<T>,
    backStackDepth: Int,
    modifier: Modifier,
    content: @Composable (T) -> Unit,
  ) {
    Box(
      modifier = modifier.fillMaxSize(),
      propagateMinConstraints = true,
    ) {
      args.reversed()
        .forEach { entry ->
          key(entry) {
            val presentation = remember(entry) {
              (entry as? RecordContentProvider<*>)?.record?.screen?.let {
                (it as Destination).presentation
              }
            }
            when (presentation) {
              is Presentation.Overlay -> {
                OverlayContent(
                  dismissOnClick = presentation.dismissOnClick,
                  onHidden = onHidden,
                  scrimColor = AppTheme.scrimColor,
                  content = { content(entry) },
                )
              }

              is Presentation.Sheet -> {
                SheetContent(
                  origin = presentation.origin,
                  onHidden = onHidden,
                  showScrim = (backStackDepth == 1),
                  scrimColor = AppTheme.scrimColor,
                  content = { content(entry) },
                )
              }

              else -> error("Unsupported presentation: $presentation")
            }
          }
        }
    }
  }
}

@OptIn(ExperimentalAnimationApi::class)
@Composable
private fun OverlayContent(
  dismissOnClick: Boolean,
  onHidden: () -> Unit,
  scrimColor: Color,
  content: @Composable () -> Unit,
) {
  val visible = remember { MutableTransitionState(initialState = false) }
  val transition = updateTransition(transitionState = visible, label = "visible")

  LaunchedEffect(Unit) {
    visible.targetState = true
    snapshotFlow { visible.isIdle }.firstOrNull { it }
    withFrameMillis {} // wait for animation to complete
    snapshotFlow { visible.currentState }.filter { !it }.firstOrNull()
    withFrameMillis {} // wait for animation to complete
    onHidden()
  }

  BackHandler(
    enabled = visible.currentState && visible.targetState,
  ) {
    visible.targetState = false
  }

  Box(
    modifier = Modifier
      .fillMaxSize()
      .background(
        transition.animateColor(label = "scrim") {
          if (it) scrimColor else scrimColor.copy(alpha = 0f)
        }.value
      )
      .runIf(dismissOnClick) {
        pointerInput(visible) {
          detectTapGestures { visible.targetState = false }
        }
      },
    propagateMinConstraints = true,
  ) {
    transition.AnimatedVisibility(
      visible = { it },
      enter = OverlayEnterTransition,
      exit = OverlayExitTransition,
    ) {
      Box(
        modifier = Modifier.fillMaxSize(),
        propagateMinConstraints = true,
      ) {
        content()
      }
    }
  }
}

private val OverlayEnterTransition = fadeIn() + scaleIn(
  animationSpec = tween(),
  initialScale = 0.95f,
  transformOrigin = TransformOrigin.Center,
)

private val OverlayExitTransition = fadeOut() + scaleOut(
  animationSpec = tween(),
  targetScale = 0.95f,
  transformOrigin = TransformOrigin.Center,
)

@Composable
private fun SheetContent(
  origin: SheetOrigin,
  onHidden: () -> Unit,
  showScrim: Boolean,
  scrimColor: Color,
  content: @Composable () -> Unit,
) {
  val sheetState = rememberSheetState(SheetVisibility.Hidden)

  LaunchedEffect(Unit) {
    sheetState.show()
    withFrameMillis {} // wait for animation to complete
    snapshotFlow { sheetState.currentVisibility }.filter { it == SheetVisibility.Hidden }.firstOrNull()
    withFrameMillis {} // wait for animation to complete
    onHidden()
  }

  val scope = rememberCoroutineScope()

  BackHandler(enabled = sheetState.isFullyVisible) {
    scope.launch { sheetState.hide() }
  }

  SheetLayout(
    origin = origin,
    sheetState = sheetState,
    scrimColor = if (showScrim) scrimColor else Color.Transparent,
    content = {
      Box(
        modifier = Modifier.tapToDismissKeyboard(),
        propagateMinConstraints = true,
      ) {
        content()
      }
    },
  )
}
