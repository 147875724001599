package app.megachat.client.ui.design.user.boid

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.platform.LocalInspectionMode
import app.megachat.shared.base.data.UserId
import app.megachat.shared.base.util.emptyImmutableMap
import app.megachat.shared.base.util.toImmutableMap
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import kotlinx.collections.immutable.ImmutableMap
import kotlinx.collections.immutable.ImmutableSet
import kotlinx.coroutines.delay
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant

@Composable
internal fun rememberUserBoidsBouncer(
  userIds: ImmutableSet<UserId>
): UserBoidsBouncer =
  if (LocalInspectionMode.current) {
    remember {
      UserBoidsBouncerNoop()
    }
  } else {
    remember {
      UserBoidsBouncerImpl()
    }
      .also {
        LaunchedEffect(userIds) {
          while (true) {
            delay(it.updateInterval)
            it.update(userIds)
          }
        }
      }
  }
    .also {
      it.update(userIds)
    }

interface UserBoidsBouncer {

  val updateInterval: Duration

  /** True means the boid should be appearing or fully visible. Otherwise - disappearing. */
  val userIds: ImmutableMap<UserId, Boolean>

  fun update(maxVisibleBoids: Int)

  fun update(userIds: ImmutableSet<UserId>)
}

private class UserBoidsBouncerNoop : UserBoidsBouncer {

  override val updateInterval = Duration.INFINITE

  override var userIds: ImmutableMap<UserId, Boolean> by mutableStateOf(emptyImmutableMap())
    private set

  override fun update(maxVisibleBoids: Int) {}

  override fun update(userIds: ImmutableSet<UserId>) {
    this.userIds = userIds.associateWith { true }.toImmutableMap()
  }
}

private class UserBoidsBouncerImpl(
  private val clock: Clock = Clock.System,
) : UserBoidsBouncer {

  override val updateInterval = 1.seconds

  override var userIds: ImmutableMap<UserId, Boolean> by mutableStateOf(emptyImmutableMap())
    private set

  override fun update(maxVisibleBoids: Int) {
    require(maxVisibleBoids >= 0)
    this.maxVisibleBoids = maxVisibleBoids
  }

  override fun update(userIds: ImmutableSet<UserId>) {
    val now = clock.now()

    // Purge all non-current:
    val visible = this.userIds.keys.toMutableSet().apply { retainAll(userIds) }
    disappearing.keys.retainAll(userIds)
    invisible.retainAll(userIds)

    // Recycle disappeared:
    disappearing.filterValues { it < now }.keys.also { disappeared ->
      visible.removeAll(disappeared)
      disappearing.keys.removeAll(disappeared)
      invisible.addAll(disappeared.intersect(userIds))
    }

    // Enqueue invisible:
    invisible.addAll(userIds - visible)

    // Appear or disappear:
    when {
      invisible.isEmpty() -> Unit // Nothing to do

      visible.isEmpty() -> // Appear a bunch initially, but allow ~2 to appear thereafter:
        invisible.take((maxVisibleBoids - 2).coerceAtLeast(1)).toSet().also { toAppear ->
          invisible.removeAll(toAppear)
          visible.addAll(toAppear)
          lastChangedAt = now
        }

      now - lastChangedAt < updateInterval / 2 -> Unit // Too soon to change

      visible.size < maxVisibleBoids -> // Appear one:
        invisible.first().also { toAppear ->
          invisible.remove(toAppear)
          visible.add(toAppear)
          lastChangedAt = now
        }

      else -> // Disappear one:
        visible.first().let { toDisappear ->
          disappearing[toDisappear] = now + updateInterval
          lastChangedAt = now
        }
    }

    this.userIds = visible.associateWith { it !in disappearing.keys }.toImmutableMap()
  }

  private var lastChangedAt: Instant = Instant.DISTANT_PAST

  private var maxVisibleBoids: Int = 0

  private val disappearing: MutableMap<UserId, Instant> = mutableMapOf()
  private val invisible: MutableSet<UserId> = mutableSetOf()
}
