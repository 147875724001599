package app.megachat.shared.base.data

data class BuildConfig(
  val appVersion: String,
  val isDebug: Boolean,
) {

  companion object {
    val EMPTY = BuildConfig(
      appVersion = "",
      isDebug = false,
    )
  }
}
