package app.megachat.client.ui.core.circuit

import app.megachat.client.ui.core.navigation.Navigator.Companion.unwrap
import app.megachat.shared.base.koin.rootScope
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import org.koin.core.parameter.parametersOf
import org.koin.core.qualifier.TypeQualifier
import com.slack.circuit.runtime.Navigator as CircuitNavigator

internal class KoinPresenterFactory : Presenter.Factory {

  override fun create(
    screen: Screen,
    navigator: CircuitNavigator,
    context: CircuitContext,
  ): Presenter<*>? =
    rootScope.getOrNull(TypeQualifier(screen::class)) {
      parametersOf(screen, navigator.unwrap(), context)
    }
}
