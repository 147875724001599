package app.megachat.client.ui.design.util

import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Outline
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection

fun Shape.toPath(
  size: Size,
  layoutDirection: LayoutDirection,
  density: Density,
): Path {
  val composeOutline = createOutline(
    size = size,
    layoutDirection = layoutDirection,
    density = density,
  )
  return when (composeOutline) {
    is Outline.Rectangle -> Path().apply { addRect(composeOutline.rect) }
    is Outline.Rounded -> Path().apply { addRoundRect(composeOutline.roundRect) }
    is Outline.Generic -> composeOutline.path
  }
}
