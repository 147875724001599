package app.megachat.website.browser

import org.koin.core.module.Module
import org.koin.dsl.module

internal val browserModule = module {
  includes(
    browserPlatformModule,
  )
}

internal expect val browserPlatformModule: Module
