package app.megachat.website.config

internal object WebsiteConfigs {

  val PROD = WebsiteConfig(
    isProduction = true,
    baseUrls = listOf(
      "https://megachat.app",
      "https://mega-chat-prod.web.app",
      "https://mega-chat-prod.firebaseapp.com",
    ),
    displayName = "PROD",
  )

  val TEST = WebsiteConfig(
    isProduction = false,
    displayName = "TEST",
    baseUrls = listOf(
      "https://mega-chat-test.web.app",
      "https://mega-chat-test.firebaseapp.com",
    ),
  )

  val DEV = WebsiteConfig(
    isProduction = false,
    displayName = "DEV",
    baseUrls = listOf(
      "http://localhost:8080",
    ),
  )

  fun fromBaseUrl(baseUrl: String): WebsiteConfig =
    listOf(PROD, TEST)
      .firstOrNull { it.baseUrls.any { baseUrl.startsWith(it) } }
      ?: DEV
}
