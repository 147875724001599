package app.megachat.client.ui.design.boid

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.dp
import kotlin.math.sqrt

data class Vector3d(
  val x: Dp,
  val y: Dp,
  val z: Dp,
) {
  operator fun plus(other: Vector3d): Vector3d =
    Vector3d(
      x = x + other.x,
      y = y + other.y,
      z = z + other.z,
    )

  fun getDistance(): Dp =
    sqrt((x.value * x.value) + (y.value * y.value) + (z.value * z.value)).dp

  operator fun div(value: Float): Vector3d =
    Vector3d(
      x = x / value,
      y = y / value,
      z = z / value,
    )

  operator fun minus(other: Vector3d): Vector3d =
    Vector3d(
      x = x - other.x,
      y = y - other.y,
      z = z - other.z,
    )

  operator fun times(value: Float): Vector3d =
    Vector3d(
      x = x * value,
      y = y * value,
      z = z * value,
    )

  fun toOffset(): DpOffset = DpOffset(x = x, y = y)

  companion object {
    val Zero: Vector3d = Vector3d(0.dp, 0.dp, 0.dp)
  }
}
