package app.megachat.shared.base.data

import app.megachat.shared.base.parcel.Parcelable
import app.megachat.shared.base.parcel.Parcelize
import kotlin.jvm.JvmInline

@Parcelize
@JvmInline
value class UserId(val token: String) : Parcelable {

  override fun toString() = "UserId($token)"

  init {
    if (token != "") {
      require(token.length <= MAX_LENGTH)
      require(token.length >= MIN_LENGTH)
    }
  }

  companion object {
    val EMPTY = UserId("")

    const val MAX_LENGTH = 15 // in the database
    const val MIN_LENGTH = 7 // in the database
  }
}
