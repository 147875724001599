package app.megachat.client.ui.design.theme

import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.darkColorScheme
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.ProvidableCompositionLocal
import androidx.compose.runtime.remember
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.luminance
import app.megachat.client.ui.design.media.ImageLoaders
import app.megachat.client.ui.design.media.LocalImageLoaders

@Composable
fun AppTheme(
  theme: AppTheme.Type,
  imageLoaders: ImageLoaders,
  content: @Composable () -> Unit,
) {
  CompositionLocalProvider(
    LocalImageLoaders provides imageLoaders,
  ) {
    AppTheme(
      theme = theme,
    ) {
      content()
    }
  }
}

@Composable
fun AppTheme(
  theme: AppTheme.Type,
  content: @Composable () -> Unit,
) {
  val colorScheme = remember(theme) {
    when (theme) {
      AppTheme.Type.Light -> lightColorScheme
      AppTheme.Type.Dark -> darkColorScheme
    }
  }

  MaterialTheme(
    colorScheme = colorScheme,
  ) {
    CompositionLocalProvider(
      LocalAppThemeType provides theme,
      LocalBackgroundColor provides colorScheme.background,
      LocalContentColor provides colorScheme.onBackground,
    ) {
      content()
    }
  }
}

object AppTheme {

  enum class Type(val serialName: String) {
    Dark(serialName = "dark"),
    Light(serialName = "light"),
  }

  val theme: Type
    @Composable get() = LocalAppThemeType.current

  val primaryColor: Color
    @Composable get() = MaterialTheme.colorScheme.primary

  val onBackgroundColor: Color
    @Composable get() = MaterialTheme.colorScheme.onBackground

  val onBackgroundBrush: Brush
    @Composable get() {
      val color = MaterialTheme.colorScheme.onBackground
      return remember(color) { SolidColor(color) }
    }

  val backgroundColor: Color
    @Composable get() = MaterialTheme.colorScheme.background

  val backgroundBrush: Brush
    @Composable get() {
      val color = MaterialTheme.colorScheme.background
      return remember(color) { SolidColor(color) }
    }

  @Composable
  fun shadowColorFor(textColor: Color?): Color =
    textColor
      ?.let { if (it.luminance() > 0.5f) Color.Black else Color.White }
      ?: backgroundColor

  val onSurfaceColor: Color
    @Composable get() = MaterialTheme.colorScheme.onSurface

  val surfaceColor: Color
    @Composable get() = MaterialTheme.colorScheme.surface

  val errorColor: Color
    @Composable get() = MaterialTheme.colorScheme.error

  val scrimColor = Color.Black.copy(alpha = 0.75f)

  // TODO: change to ...
  val awayColor: Color
    @Composable get() = when (theme) {
      Type.Dark -> Color(0xFFd9d63d) // TODO
      Type.Light -> Color(0xFFe8e548) // TODO
    }

  // TODO: change to ...
  val onlineColor: Color
    @Composable get() = when (theme) {
      Type.Dark -> Color(0xFF39b845) // TODO
      Type.Light -> Color(0xFF32bf3f) // TODO
    }

  val offlineColor: Color
    @Composable get() = when (theme) {
      Type.Dark -> Color.Gray
      Type.Light -> Color.LightGray
    }
}

private val lightColorScheme = lightColorScheme(
  primary = Color(0xFF7223A3),
  error = Color(0xFFF5223B),
)

private val darkColorScheme = darkColorScheme(
  primary = Color(0xFFBEACFF),
  error = Color(0xFFEB7C8A),
)

internal val LocalAppThemeType: ProvidableCompositionLocal<AppTheme.Type> =
  staticCompositionLocalOf { error("LocalAppThemeType is not provided!") }
