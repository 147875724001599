package app.megachat.client.ui.core.navigation

import androidx.compose.runtime.Immutable
import app.megachat.client.ui.design.components.sheet.SheetOrigin
import com.slack.circuit.runtime.screen.Screen as CircuitScreen

@Immutable
abstract class Destination(
  val presentation: Presentation,
) : CircuitScreen {

  @Immutable
  sealed interface Presentation {
    data class FullScreen(
      val isRoot: Boolean = false,
    ): Presentation

    data class Overlay(
      val dismissOnClick: Boolean = false,
    ): Presentation

    data class Sheet(
      val origin: SheetOrigin = SheetOrigin.Bottom,
    ): Presentation
  }
}
