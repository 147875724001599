package app.megachat.website

import app.megachat.client.ui.core.ClientUiCoreModule
import app.megachat.shared.base.SharedBaseModule
import app.megachat.website.about.About
import app.megachat.website.browser.browserModule
import app.megachat.website.coil.coilModule
import app.megachat.website.config.configModule
import app.megachat.website.main.Main
import app.megachat.website.welcome.Welcome
import org.koin.dsl.module

internal val WebsiteModule = module {
  includes(
    SharedBaseModule,
    ClientUiCoreModule,
    browserModule,
    coilModule,
    configModule,
  )
  includes(
    About.Module,
    Main.Module,
    Welcome.Module,
  )
}
