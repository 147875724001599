package app.megachat.shared.base.log

import co.touchlab.kermit.Severity

class Logger(val tag: String) {

  enum class Level {
    DEBUG, INFO, WARNING, ERROR;
  }

  inline fun e(throwable: Throwable? = null, noinline message: () -> String) =
    kermitLogger.e(tag, throwable, message)

  inline fun w(throwable: Throwable? = null, noinline message: () -> String) =
    kermitLogger.w(tag, throwable, message)

  inline fun i(noinline message: () -> String) =
    kermitLogger.i(tag, throwable = null, message)

  inline fun d(noinline message: () -> String) =
    kermitLogger.d(tag, throwable = null, message)

  companion object {
    val kermitLogger = co.touchlab.kermit.Logger

    fun setMinLogLevel(level: Level) {
      kermitLogger.setMinSeverity(
        when (level) {
          Level.DEBUG -> Severity.Debug
          Level.INFO -> Severity.Info
          Level.WARNING -> Severity.Warn
          Level.ERROR -> Severity.Error
        }
      )
    }
  }
}
