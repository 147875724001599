package app.megachat.client.ui.design.media

import androidx.compose.runtime.Immutable
import app.megachat.shared.base.util.lazyPub
import coil3.ImageLoader

@Immutable
interface ImageLoaders {
  val default: ImageLoader

  companion object {
    val EMPTY by lazyPub {
      emptyImageLoaders()
    }
  }
}

internal expect fun emptyImageLoaders(): ImageLoaders
