package app.megachat.client.ui.design.components.sheet

import androidx.compose.animation.core.TweenSpec
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.gestures.AnchoredDraggableState
import androidx.compose.foundation.gestures.DraggableAnchors
import androidx.compose.foundation.gestures.Orientation
import androidx.compose.foundation.gestures.anchoredDraggable
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.WindowInsetsSides
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.ime
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.only
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.union
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.isSpecified
import androidx.compose.ui.input.nestedscroll.NestedScrollConnection
import androidx.compose.ui.input.nestedscroll.NestedScrollSource
import androidx.compose.ui.input.nestedscroll.nestedScroll
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.semantics.dismiss
import androidx.compose.ui.semantics.onClick
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.Velocity
import app.megachat.client.ui.design.components.sheet.SheetVisibility.Expanded
import app.megachat.client.ui.design.components.sheet.SheetVisibility.Hidden
import app.megachat.client.ui.design.theme.Dimensions
import app.megachat.client.ui.design.theme.Shapes
import app.megachat.shared.base.util.runIf
import kotlin.jvm.JvmName
import kotlin.math.max
import kotlin.math.roundToInt
import kotlinx.coroutines.launch

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun SheetLayout(
  origin: SheetOrigin,
  modifier: Modifier = Modifier,
  sheetState: SheetState = rememberSheetState(Hidden),
  sheetShape: Shape = when (origin) {
    SheetOrigin.Bottom -> Shapes.bottomSheet
    else -> Shapes.sheet
  },
  scrimColor: Color,
  content: @Composable () -> Unit,
) {
  val scope = rememberCoroutineScope()
  Box(
    modifier = modifier,
  ) {
    Scrim(
      state = sheetState,
      color = scrimColor,
      onDismiss = { scope.launch { sheetState.hide() } },
    )
    BoxWithConstraints(
      modifier = (Modifier as Modifier)
        .runIf(origin == SheetOrigin.Bottom) {
          windowInsetsPadding(
            WindowInsets.safeDrawing.only(WindowInsetsSides.Top + WindowInsetsSides.Horizontal)
              .union(WindowInsets.ime.only(WindowInsetsSides.Bottom))
          )
        }
        .fillMaxSize()
    ) {
      val fullSize = Size(
        width = constraints.maxWidth.toFloat(),
        height = constraints.maxHeight.toFloat(),
      )
      Column(
        modifier = Modifier
          .align(Alignment.BottomCenter)
          .widthIn(max = Dimensions.maxSheetWidth(screenWidth = maxWidth))
          .align(
            when (origin) {
              SheetOrigin.Bottom -> Alignment.BottomCenter
              SheetOrigin.End -> Alignment.CenterStart
              SheetOrigin.Start -> Alignment.CenterStart
            }
          )
          .nestedScroll(
            remember(sheetState.swipeableState, origin.swipeOrientation) {
              ConsumeSwipeWithinBottomSheetBoundsNestedScrollConnection(
                state = sheetState.swipeableState,
                orientation = origin.swipeOrientation,
              )
            }
          )
          .offset {
            val offset = sheetState.swipeableState.offset
              .takeIf { it.isFinite() }
              ?.roundToInt() ?: 0
            when (origin) {
              SheetOrigin.Bottom -> IntOffset(x = 0, y = offset)
              SheetOrigin.End -> IntOffset(x = offset, y = 0)
              SheetOrigin.Start -> IntOffset(x = -offset, y = 0)
            }
          }
          .anchoredDraggable(
            state = sheetState.swipeableState,
            orientation = origin.swipeOrientation,
            reverseDirection = origin == SheetOrigin.Start,
            enabled = sheetState.swipeableState.currentValue != Hidden,
          )
          .onSizeChanged { sheetSize ->
            if (sheetSize.width <= 0 || sheetSize.height <= 0) return@onSizeChanged

            sheetState.swipeableState.updateAnchors(
              newAnchors = DraggableAnchors {
                when (origin) {
                  SheetOrigin.Bottom -> {
                    Hidden at sheetSize.height.toFloat()
                    Expanded at 0f
                  }

                  SheetOrigin.End -> {
                    // + padding to animate fully off screen
                    Hidden at fullSize.width
                    Expanded at max(0f, fullSize.width - sheetSize.width)
                  }

                  SheetOrigin.Start -> {
                    Hidden at fullSize.width
                    Expanded at 0f
                  }
                }
              },
              newTarget = sheetState.targetVisibility,
            )
          }
          .runIf(sheetState.isFullyVisible) {
            semantics {
              dismiss {
                scope.launch { sheetState.hide() }
                true
              }
            }
          }
          .clip(sheetShape),
        content = { content() },
      )
    }
  }
}

@Composable
private fun Scrim(
  state: SheetState,
  color: Color,
  onDismiss: () -> Unit,
) {
  if (color.isSpecified) {
    val alpha by animateFloatAsState(
      targetValue = if (state.targetVisibility != Hidden) 1f else 0f,
      animationSpec = TweenSpec(),
      label = "scrim alpha",
    )
    Canvas(
      Modifier
        .fillMaxSize()
        .runIf(state.isFullyVisible) {
          pointerInput(onDismiss) { detectTapGestures { onDismiss() } }
            .semantics(mergeDescendants = true) {
              contentDescription = "close" // TODO?
              onClick { onDismiss(); true }
            }
        }
    ) {
      drawRect(color = color, alpha = alpha)
    }
  }
}

@OptIn(ExperimentalFoundationApi::class)
@Suppress("FunctionName")
private fun ConsumeSwipeWithinBottomSheetBoundsNestedScrollConnection(
  state: AnchoredDraggableState<*>,
  orientation: Orientation,
): NestedScrollConnection = object : NestedScrollConnection {
  override fun onPreScroll(available: Offset, source: NestedScrollSource): Offset {
    val delta = available.toFloat()
    return if (delta < 0 && source == NestedScrollSource.Drag) {
      state.dispatchRawDelta(delta).toOffset()
    } else {
      Offset.Zero
    }
  }

  override fun onPostScroll(
    consumed: Offset,
    available: Offset,
    source: NestedScrollSource,
  ): Offset {
    return if (source == NestedScrollSource.Drag) {
      state.dispatchRawDelta(available.toFloat()).toOffset()
    } else {
      Offset.Zero
    }
  }

  override suspend fun onPreFling(available: Velocity): Velocity {
    val toFling = available.toFloat()
    val currentOffset = state.requireOffset()
    return if (toFling < 0 && currentOffset > state.anchors.minAnchor()) {
      state.settle(velocity = toFling)
      // since we go to the anchor with tween settling, consume all for the best UX
      available
    } else {
      Velocity.Zero
    }
  }

  override suspend fun onPostFling(consumed: Velocity, available: Velocity): Velocity {
    state.settle(velocity = available.toFloat())
    return available
  }

  private fun Float.toOffset(): Offset = Offset(
    x = if (orientation == Orientation.Horizontal) this else 0f,
    y = if (orientation == Orientation.Vertical) this else 0f
  )

  @JvmName("velocityToFloat")
  private fun Velocity.toFloat() = if (orientation == Orientation.Horizontal) x else y

  @JvmName("offsetToFloat")
  private fun Offset.toFloat(): Float = if (orientation == Orientation.Horizontal) x else y
}
