package app.megachat.website

import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.ComposeViewport
import app.megachat.website.koin.startKoin
import kotlinx.browser.document
import org.jetbrains.skiko.wasm.onWasmReady

@OptIn(ExperimentalComposeUiApi::class)
@JsName("_main")
fun main() {
  startKoin()

  onWasmReady {
    ComposeViewport(document.body!!) {
      MainContent()
    }
  }
}
