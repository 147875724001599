package app.megachat.shared.base

import app.megachat.shared.base.koin.authScope
import app.megachat.shared.base.koin.rootScope
import app.megachat.shared.base.koin.userScope
import kotlin.random.Random
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import kotlinx.datetime.Clock
import org.koin.core.module.Module
import org.koin.dsl.module

val SharedBaseModule = module {
  includes(
    sharedBasePlatformModule,
  )

  single {
    CoroutineScope(Dispatchers.Main)
  }

  single<Random> {
    Random
  }

  single<Clock> {
    Clock.System
  }

  authScope {
    scoped<CoroutineScope> {
      rootScope.get<CoroutineScope>().coroutineContext.let {
        CoroutineScope(it + SupervisorJob(it[Job]))
      }
    }
  }

  userScope {
    scoped<CoroutineScope> {
      authScope.get<CoroutineScope>().coroutineContext.let {
        CoroutineScope(it + SupervisorJob(it[Job]))
      }
    }
  }
}

internal expect val sharedBasePlatformModule: Module
