package app.megachat.client.ui.design.components

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import app.megachat.client.ui.design.theme.Dimensions.Gap25
import app.megachat.client.ui.design.theme.Dimensions.Gap50

@Composable
fun TopIndicator(
  text: String,
  backgroundColor: Color,
) {
  Text(
    modifier = Modifier
      .clip(RoundedCornerShape(bottomEnd = Gap50, bottomStart = Gap50))
      .background(backgroundColor.copy(alpha = 0.75f))
      .padding(horizontal = Gap25),
    text = text,
    color = Color.Black,
    fontSize = 10.sp,
    fontWeight = FontWeight.W800,
    lineHeight = 6.sp,
  )
}
