package app.megachat.client.ui.design.custom

import androidx.compose.runtime.Immutable

@Immutable
sealed interface CustomBorder {
  val key: String
  val pickdata: Pickdata?

  data class Solid(
    val width: Int,
    override val pickdata: Pickdata? = null,
  ) : CustomBorder {
    override val key = "Solid$width"
  }

  data class Dashed(
    val width: Int,
    override val pickdata: Pickdata? = null,
  ) : CustomBorder {
    override val key = "Dashed$width"
  }

  data class Dotted(
    val width: Int,
    override val pickdata: Pickdata? = null,
  ) : CustomBorder {
    override val key = "Dotted$width"
  }

  data class DashDot(
    val width: Int,
    override val pickdata: Pickdata? = null,
  ) : CustomBorder {
    override val key = "DashDot$width"
  }

  data object Unknown : CustomBorder {
    override val key = "Unknown"
    override val pickdata = null
  }

  companion object
}
