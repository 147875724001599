package app.megachat.shared.base.util

import kotlinx.collections.immutable.ImmutableMap
import kotlinx.collections.immutable.persistentMapOf
import kotlinx.collections.immutable.toPersistentMap

inline fun <K, V> emptyImmutableMap(): ImmutableMap<K, V> =
  persistentMapOf()

inline fun <K, V> immutableMapOf(vararg elements: Pair<K, V>): ImmutableMap<K, V> =
  persistentMapOf(*elements)

inline fun <K, V> Map<K, V>.toImmutableMap(): ImmutableMap<K, V> =
  toPersistentMap()

inline fun <K, V> ImmutableMap<K, V>?.orEmpty(): ImmutableMap<K, V> =
  this ?: emptyImmutableMap()
