package app.megachat.website.config

import app.megachat.website.browser.Browser
import org.koin.dsl.module

internal val configModule = module {

  single<WebsiteConfig> {
    val browser = get<Browser>()
    WebsiteConfigs.fromBaseUrl(
      baseUrl = browser.baseUrl,
    )
  }
}
