package app.megachat.shared.base.koin

import org.koin.core.Koin
import org.koin.core.KoinApplication
import org.koin.core.qualifier.named
import org.koin.core.scope.Scope
import org.koin.mp.KoinPlatformTools

private val ROOT_SCOPE_ID = named("_root_") // See org.koin.core.registry.ScopeRegistry.ROOT_SCOPE_ID

val Scope.rootScope: Scope
  get() = getScope(ROOT_SCOPE_ID.value)

val KoinApplication.rootScope: Scope
  get() = koin.rootScope

val rootScope: Scope
  get() = KoinPlatformTools.defaultContext().get().rootScope

private val Koin.rootScope: Scope
  inline get() = getScope(ROOT_SCOPE_ID.value)
