package app.megachat.client.ui.design.custom

import androidx.compose.runtime.Immutable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.toArgb

@Immutable
sealed interface CustomBrush {
  val key: String
  val pickdata: Pickdata?

  data class Solid(
    val color: Color,
  ) : CustomBrush {
    override val key = "Solid_${color.toArgb()}"
    override val pickdata = null
  }

  data class SweepRgb(
    override val pickdata: Pickdata? = null,
  ) : CustomBrush {
    override val key = "SweepRgb"
  }

  data object Unknown : CustomBrush {
    override val key = "Unknown"
    override val pickdata = null
  }

  companion object
}
