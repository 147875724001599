package app.megachat.client.ui.design

import app.megachat.client.ui.design.emoji.emojiModule
import org.koin.dsl.module

val ClientUiDesignModule = module {
  includes(
    emojiModule,
  )
}
