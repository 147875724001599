package app.megachat.client.ui.design.components

import androidx.compose.foundation.layout.RowScope
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.material3.TopAppBar as MaterialTopAppBar

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun TopAppBar(
  title: @Composable () -> Unit,
  modifier: Modifier = Modifier,
  navigationIcon: @Composable (() -> Unit)? = null,
  actions: @Composable RowScope.() -> Unit = {},
) {
  MaterialTopAppBar(
    title = title,
    modifier = modifier,
    navigationIcon = {
      if (navigationIcon != null) {
        navigationIcon()
      }
    },
    actions = actions,
    colors = TopAppBarDefaults.topAppBarColors().copy(
      containerColor = Color.Transparent,
    )
  )
}
