package app.megachat.client.ui.design.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import app.megachat.shared.base.util.applyIf

/**
 * Similar to the standard [androidx.compose.runtime.rememberUpdatedState] method,
 * but the value is updated only when [predicate] is true.
 */
@Composable
fun <T> rememberUpdatedStateIf(predicate: Boolean, newValue: T): State<T> =
  remember { mutableStateOf(newValue) }
    .applyIf(predicate) { value = newValue }
