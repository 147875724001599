package app.megachat.client.ui.design.custom

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.border
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Dp
import app.megachat.client.ui.design.util.dashDotBorder
import app.megachat.client.ui.design.util.dashedBorder
import app.megachat.client.ui.design.util.dottedBorder

@Composable
fun rememberCustomBorder(
  customBorder: CustomBorder?,
  brush: Brush,
  shape: Shape,
  size: Dp,
): Modifier? =
  remember(customBorder, brush, shape, size) {
    when (customBorder) {

      is CustomBorder.Solid -> when (customBorder.width) {
        1 -> CustomBorders.Solid1(brush, shape, size)
        2 -> CustomBorders.Solid2(brush, shape, size)
        4 -> CustomBorders.Solid4(brush, shape, size)
        else -> null // TODO: log?
      }

      is CustomBorder.Dashed -> when (customBorder.width) {
        1 -> CustomBorders.Dashed1(brush, shape, size)
        2 -> CustomBorders.Dashed2(brush, shape, size)
        4 -> CustomBorders.Dashed4(brush, shape, size)
        else -> null // TODO: log?
      }

      is CustomBorder.Dotted -> when (customBorder.width) {
        4 -> CustomBorders.Dotted4(brush, shape, size)
        8 -> CustomBorders.Dotted8(brush, shape, size)
        16 -> CustomBorders.Dotted16(brush, shape, size)
        else -> null // TODO: log?
      }

      is CustomBorder.DashDot -> when (customBorder.width) {
        4 -> CustomBorders.DashDot4(brush, shape, size)
        6 -> CustomBorders.DashDot6(brush, shape, size)
        8 -> CustomBorders.DashDot8(brush, shape, size)
        else -> null // TODO: log?
      }

      is CustomBorder.Unknown -> null // TODO: log?

      null -> null
    }
  }

@Suppress("FunctionName")
object CustomBorders {

  fun DashDot4(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.dashDotBorder(
      brush = brush,
      width = avatarSize * 0.04f, // 4%
      shape = shape,
    )

  fun DashDot6(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.dashDotBorder(
      brush = brush,
      width = avatarSize * 0.06f, // 6%
      shape = shape,
    )

  fun DashDot8(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.dashDotBorder(
      brush = brush,
      width = avatarSize * 0.08f, // 8%
      shape = shape,
    )

  fun Dashed1(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.dashedBorder(
      brush = brush,
      width = avatarSize * 0.01f, // 1%
      shape = shape,
    )

  fun Dashed2(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.dashedBorder(
      brush = brush,
      width = avatarSize * 0.02f, // 2%
      shape = shape,
    )

  fun Dashed4(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.dashedBorder(
      brush = brush,
      width = avatarSize * 0.04f, // 4%
      shape = shape,
    )

  fun Dotted4(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.dottedBorder(
      brush = brush,
      width = avatarSize * 0.04f, // 4%
      shape = shape,
    )

  fun Dotted8(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.dottedBorder(
      brush = brush,
      width = avatarSize * 0.08f, // 8%
      shape = shape,
    )

  fun Dotted16(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.dottedBorder(
      brush = brush,
      width = avatarSize * 0.16f, // 16%
      shape = shape,
    )

  fun Solid1(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.border(
      border = BorderStroke(
        width = avatarSize * 0.01f, // 1%
        brush = brush,
      ),
      shape = shape,
    )

  fun Solid2(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.border(
      border = BorderStroke(
        width = avatarSize * 0.02f, // 2%
        brush = brush,
      ),
      shape = shape,
    )

  fun Solid4(
    brush: Brush,
    shape: Shape,
    avatarSize: Dp,
  ) =
    Modifier.border(
      border = BorderStroke(
        width = avatarSize * 0.04f, // 4%
        brush = brush,
      ),
      shape = shape,
    )
}
