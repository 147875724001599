package app.megachat.client.ui.design.components

import androidx.compose.material3.LocalContentColor
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.material3.Icon as MaterialIcon

@Composable
fun Icon(
  painter: Painter,
  modifier: Modifier = Modifier,
  tint: Color = LocalContentColor.current,
) {
  MaterialIcon(
    painter = painter,
    contentDescription = null,
    modifier = modifier,
    tint = tint,
  )
}
