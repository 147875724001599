package app.megachat.client.ui.design.util

import androidx.compose.animation.AnimatedVisibilityScope
import androidx.compose.animation.EnterTransition
import androidx.compose.animation.ExitTransition
import androidx.compose.animation.expandVertically
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.shrinkVertically
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier

/**
 * Similar to the standard AnimatedVisibility, but it passes the last known visible [state]
 * to the [content] lambda, so that it doesn't visually change during the [exit] animation.
 */
@Composable
fun <T: Any> AnimatedVisibility(
  visible: Boolean,
  state: T?,
  modifier: Modifier = Modifier,
  enter: EnterTransition = fadeIn() + expandVertically(),
  exit: ExitTransition = shrinkVertically() + fadeOut(),
  label: String = "AnimatedVisibility",
  content: @Composable AnimatedVisibilityScope.(T) -> Unit
) {
  androidx.compose.animation.AnimatedVisibility(
    visible = visible,
    modifier = modifier,
    enter = enter,
    exit = exit,
    label = label,
  ) {
    rememberUpdatedStateIf(visible, state).value?.let { visibleState ->
      content(visibleState)
    }
  }
}
