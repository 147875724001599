package app.megachat.website.welcome

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.graphics.Color
import app.megachat.client.ui.core.navigation.Navigator
import app.megachat.client.ui.core.navigation.Presenter
import app.megachat.client.ui.design.avatar.AvatarStyle
import app.megachat.client.ui.design.custom.CustomBorder
import app.megachat.client.ui.design.custom.CustomBrush
import app.megachat.client.ui.design.custom.CustomShape
import app.megachat.client.ui.design.user.UserSummary
import app.megachat.shared.base.data.BuildConfig
import app.megachat.shared.base.data.UserId
import app.megachat.shared.base.util.immutableMapOf
import app.megachat.website.about.AboutScreen
import app.megachat.website.browser.Browser
import app.megachat.website.welcome.Welcome.Event
import app.megachat.website.welcome.Welcome.State

internal class WelcomePresenter(
  private val browser: Browser,
  private val buildConfig: BuildConfig,
  private val navigator: Navigator,
) : Presenter<State, Event> {

  @Composable
  override fun present(): State {

    val boidUsers = remember {
      immutableMapOf(
        UserId("user-id-M") to UserSummary.EMPTY.copy(
          displayName = "M",
          avatarStyle = AvatarStyle(
            border = CustomBorder.Solid(width = 1),
            brush = CustomBrush.Solid(Color(0xFFBBAAFF)),
            shape = CustomShape.Circle(),
          ),
        ),
        UserId("user-id-E") to UserSummary.EMPTY.copy(
          displayName = "E",
          avatarStyle = AvatarStyle(
            border = null,
            brush = CustomBrush.Solid(Color(0xFF1AAABF)),
            shape = CustomShape.Square20V(),
          ),
        ),
        UserId("user-id-G") to UserSummary.EMPTY.copy(
          displayName = "G",
          avatarStyle = AvatarStyle(
            border = CustomBorder.Dashed(width = 1),
            brush = CustomBrush.Solid(Color(0xFFAFFABF)),
            shape = CustomShape.Triangle50V(),
          ),
        ),
        UserId("user-id-A") to UserSummary.EMPTY.copy(
          displayName = "A",
          avatarStyle = AvatarStyle(
            border = CustomBorder.Dashed(width = 2),
            brush = CustomBrush.Solid(Color(0xFFAA9F9F)),
            shape = CustomShape.Heart(),
          ),
        ),
      )
    }

    return State(
      boidUsers = boidUsers,
      version = buildConfig.appVersion,
    ) { event ->
      when (event) {
        is Event.OnPrivacyPolicyClick ->
          browser.openUrl("https://megachat.app/privacy-policy.html")

        is Event.OnTermsAndConditionsClick ->
          browser.openUrl("https://megachat.app/terms-and-conditions.html")

        is Event.OnAboutClick ->
          navigator.goTo(AboutScreen)
      }
    }
  }
}
