package app.megachat.client.ui.design.boid

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import app.megachat.client.ui.design.boid.BoidSimulator.Boid
import app.megachat.client.ui.design.boid.BoidSimulator.Simulation
import kotlinx.collections.immutable.ImmutableList
import kotlinx.collections.immutable.ImmutableMap
import kotlinx.collections.immutable.PersistentMap

//
// See https://vergenet.net/~conrad/boids/pseudocode.html
//
class BoidSimulator<K : Any, B : Boid<K>, S : Simulation<K, B>>(
  private val simulation: S,
  private val rules: ImmutableList<Rule<K, B, S>>,
  private val speedLimit: Dp? = 128.dp,
) {
  interface Simulation<K : Any, E : Boid<K>> {
    val boids: PersistentMap<K, E>
    val bounds: Vector3d
  }

  interface Rule<K : Any, B : Boid<K>, S : Simulation<K, B>> {
    operator fun invoke(
      simulation: S,
      others: ImmutableMap<K, B>,
      boid: B,
    ): Vector3d
  }

  interface Boid<K : Any> {
    val id: K
    val center: Vector3d
    val radius: Dp
    val velocity: Vector3d
    val weight: Float // from -1 (floating) to 1 (sinking)
  }

  fun newVelocityFor(boid: B): Vector3d {
    val others = simulation.boids.remove(boid.id)
    val vRaw = rules.fold(boid.velocity) { acc, rule -> acc + rule(simulation, others, boid) }
    return boidRuleExtensionSpeedLimit(vRaw)
  }

  private fun boidRuleExtensionSpeedLimit(velocity: Vector3d): Vector3d {
    val limit = speedLimit ?: return velocity
    val speed = velocity.getDistance()
    return if (speed > limit) {
      velocity.div(speed.value).times(limit.value)
    } else {
      velocity
    }
  }
}
