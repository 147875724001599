package app.megachat.client.ui.design.util

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Path

inline fun Path.moveTo(offset: Offset) =
  moveTo(offset.x, offset.y)

inline fun Path.lineTo(offset: Offset) =
  lineTo(offset.x, offset.y)
