package app.megachat.shared.base.util

actual fun getCurrentCountryCode(): String =
  language.split("-", "_").getOrNull(1) ?: ""

actual fun getCurrentLanguageCode(): String =
  language.split("-", "_").getOrNull(0) ?: ""

// Example: "en-US"
private val language: String
  get() = kotlinx.browser.window.navigator.language
