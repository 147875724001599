package app.megachat.client.ui.design.util

import app.megachat.client.ui.design.util.Angle.Companion.DEGREES_IN_ONE_RADIAN
import app.megachat.client.ui.design.util.Angle.Companion.MAX_DEGREES
import app.megachat.client.ui.design.util.Angle.Companion.MIN_DEGREES
import app.megachat.client.ui.design.util.Angle.Companion.SECONDS_IN_ONE_DEGREE
import kotlin.math.roundToInt

val Int.degrees: Angle
  get() {
    require(this in MIN_DEGREES..MAX_DEGREES)
    return Angle(this * SECONDS_IN_ONE_DEGREE)
  }

val Float.degrees: Angle
  get() {
    require(this in MIN_DEGREES.toFloat()..MAX_DEGREES.toFloat())
    return Angle((this * SECONDS_IN_ONE_DEGREE).roundToInt())
  }

val Float.radians: Angle
  get() {
    require(this in Angle.MIN_RADIANS..Angle.MAX_RADIANS)
    return Angle((this * SECONDS_IN_ONE_DEGREE * DEGREES_IN_ONE_RADIAN).roundToInt())
  }
