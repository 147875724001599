package app.megachat.shared.base.data

sealed interface PlatformConfig {

  data class AndroidJvm(
    val osVersion: String,
    val sdkVersion: Int,
  ) : PlatformConfig {

    companion object {
      val EMPTY = AndroidJvm(
        osVersion = "",
        sdkVersion = 0,
      )
    }
  }

  data class DesktopJvm(
    val osName: String,
    val osVersion: String,
    val javaVersion: String,
  ) : PlatformConfig

  data class IosNative(
    val osName: String,
    val osVersion: String,
  ) : PlatformConfig

  data class WasmJs(
    val userAgent: String,
  ) : PlatformConfig
}
