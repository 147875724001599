package app.megachat.client.ui.design.util

import androidx.compose.runtime.Stable
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.DpSize
import androidx.compose.ui.unit.IntRect
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.unit.dp
import kotlin.math.roundToInt

@Stable
operator fun IntSize.div(other: Float): Size =
  Size(
    width = width / other,
    height = height / other,
  )

@Stable
inline fun IntSize.toDp(density: Density): DpSize =
  DpSize(
    width = (width / density.density).dp,
    height = (height / density.density).dp,
  )

@Stable
fun IntSize.centerCrop(widthByHeight: Float): IntRect =
  if (width.toFloat() / height < widthByHeight) {
    val newHeight = (width / widthByHeight).roundToInt()
    val newTop = (height - newHeight) / 2
    IntRect(
      left = 0,
      top = newTop,
      right = width,
      bottom = newTop + newHeight,
    )
  } else {
    val newWidth = (height * widthByHeight).roundToInt()
    val newLeft = (width - newWidth) / 2
    IntRect(
      left = newLeft,
      top = 0,
      right = newLeft + newWidth,
      bottom = height,
    )
  }
