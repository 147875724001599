package app.megachat.client.ui.core.circuit

import app.megachat.shared.base.koin.rootScope
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import org.koin.core.parameter.parametersOf
import org.koin.core.qualifier.TypeQualifier

internal class KoinUiFactory : Ui.Factory {

  override fun create(
    screen: Screen,
    context: CircuitContext,
  ): Ui<*>? =
    rootScope.getOrNull(TypeQualifier(screen::class)) {
      parametersOf(screen, context)
    }
}
