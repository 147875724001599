package app.megachat.client.ui.design.theme

import androidx.compose.foundation.Indication
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp

@Composable
fun ripple(
  bounded: Boolean = true,
  radius: Dp = Dp.Unspecified,
  color: Color = Color.Unspecified,
): Indication =
  androidx.compose.material3.ripple(
    bounded = bounded,
    radius = radius,
    color = color,
  )
