package app.megachat.shared.base.util

import kotlinx.collections.immutable.PersistentMap
import kotlinx.collections.immutable.persistentMapOf

fun <K, V> emptyPersistentMap(): PersistentMap<K, V> =
  persistentMapOf()

fun <K1, K2, V> PersistentMap<K1, PersistentMap<K2, V>>.mergeAll(
  other: PersistentMap<K1, PersistentMap<K2, V>>
): PersistentMap<K1, PersistentMap<K2, V>> =
  putAll(other.mapValues { (key, map) -> (get(key)?.putAll(map) ?: map) })
