package app.megachat.client.ui.core.navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.ui.Modifier
import com.slack.circuit.runtime.ui.Ui as CircuitUi

@Stable
interface Ui<S : UiState<E>, E : UiEvent> : CircuitUi<S>

inline fun <S : UiState<E>, E : UiEvent> Ui(
  crossinline content: @Composable (state: S, modifier: Modifier) -> Unit,
): Ui<S, E> =
  object : Ui<S, E> {
    @Composable
    override fun Content(state: S, modifier: Modifier) {
      content(state, modifier)
    }
  }
