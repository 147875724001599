package app.megachat.website.about

import androidx.compose.runtime.Immutable
import app.megachat.client.ui.core.koin.factory
import app.megachat.client.ui.core.navigation.Destination
import app.megachat.client.ui.core.navigation.Ui
import app.megachat.client.ui.core.navigation.UiEvent
import app.megachat.client.ui.core.navigation.UiState
import app.megachat.shared.base.koin.rootScope
import app.megachat.shared.base.parcel.Parcelize
import app.megachat.shared.base.util.lazyPub
import org.koin.dsl.module

@Parcelize
data object AboutScreen : Destination(
  presentation = Presentation.FullScreen(),
)

internal object About {

  @Immutable
  data class State(
    override val send: (Event) -> Unit,
  ) : UiState<Event> {

    companion object {
      val EMPTY by lazyPub {
        State(
          send = {},
        )
      }
    }
  }

  sealed interface Event : UiEvent {
    data object GoBack : Event
  }

  val Module = module {
    factory<AboutScreen>(
      scope = { rootScope },
      presenter = { _, navigator ->
        AboutPresenter(
          navigator = navigator,
        )
      },
      ui = { _ ->
        Ui<State, Event> { state, modifier ->
          AboutContent(state, modifier)
        }
      },
    )
  }
}
