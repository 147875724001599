package app.megachat.client.ui.design.boid

import androidx.compose.ui.unit.dp
import app.megachat.client.ui.design.boid.BoidSimulator.Boid
import app.megachat.client.ui.design.boid.BoidSimulator.Simulation
import kotlinx.collections.immutable.ImmutableMap

internal class KeepDistanceRule<K : Any, B : Boid<K>, S : Simulation<K, B>>(
  private val isTooClose: (B, B) -> Boolean = { a, b ->
    val delta = a.center - b.center
    val distance = delta.getDistance()
    val personalSpace = a.radius + b.radius + 16.dp
    distance < personalSpace
  },
) : BoidSimulator.Rule<K, B, S> {
  override fun invoke(
    simulation: S,
    others: ImmutableMap<K, B>,
    boid: B,
  ): Vector3d =
    others
      .filter { isTooClose(it.value, boid) }
      .map { it.value.center - boid.center }
      .fold(Vector3d.Zero) { acc, delta -> acc - delta }
}
