package app.megachat.client.ui.core.navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.Stable
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshotFlow
import app.megachat.shared.base.log.Logger
import com.slack.circuit.backstack.BackStack
import com.slack.circuit.backstack.BackStack.Record
import com.slack.circuit.backstack.isAtRoot
import com.slack.circuit.backstack.rememberSaveableBackStack
import com.slack.circuit.foundation.internal.BackHandler
import com.slack.circuit.foundation.rememberCircuitNavigator
import com.slack.circuit.runtime.screen.PopResult
import com.slack.circuit.runtime.Navigator as CircuitNavigator
import com.slack.circuit.runtime.screen.Screen as CircuitScreen

@Composable
fun rememberNavigator(
  initialRoot: Destination,
): Navigator {
  val circuitBackstack = rememberSaveableBackStack(initialRoot)
  val circuitNavigator = rememberCircuitNavigator(circuitBackstack) {
    // Do something when the root screen is popped, usually exiting the app
  }

  LaunchedEffect(circuitBackstack) {
    snapshotFlow { circuitBackstack.topRecord?.screen }
      .collect { log.i { "current: $it" } }
  }

  return remember(circuitNavigator) {
    Navigator(
      circuitBackstack = circuitBackstack,
      circuitNavigator = circuitNavigator,
    )
  }.also {
    BackHandler(
      enabled = !circuitBackstack.isAtRoot,
      onBack = it::pop,
    )
  }
}

@Stable
class Navigator internal constructor(
  internal val circuitBackstack: BackStack<*>,
  circuitNavigator: CircuitNavigator,
) {

  fun goTo(destination: Destination) {
    when {
      (destination.presentation as? Destination.Presentation.FullScreen)?.isRoot == true ->
        circuitNavigator.resetRoot(destination)

      else -> circuitNavigator.goTo(destination)
    }
  }

  fun pop() {
    circuitNavigator.pop()
  }

  fun pop(result: UiResult) {
    circuitNavigator.pop(result)
  }

  // NOTE: it's public for the sake of reified inline methods, such as rememberNavigatorForResult:
  val circuitNavigator: CircuitNavigator = Wrapped(circuitNavigator)

  internal inner class Wrapped(
    private val circuitNavigator: CircuitNavigator,
  ) : CircuitNavigator by circuitNavigator {
    val navigator: Navigator = this@Navigator
  }

  companion object {
    internal fun CircuitNavigator.unwrap(): Navigator =
      if (this === CircuitNavigator.NoOp) EMPTY else (this as Wrapped).navigator

    val EMPTY = Navigator(
      circuitBackstack = object : BackStack<Record> {
        override val size = 0
        override val topRecord = null
        override fun containsRecord(record: Record, includeSaved: Boolean) = false
        override fun iterator(): Iterator<Record> = emptyList<Record>().iterator()
        override fun push(screen: CircuitScreen, resultKey: String?) = false
        override fun push(record: Record, resultKey: String?) = false
        override fun pop(result: PopResult?) = null
        override fun saveState() {}
        override fun restoreState(screen: CircuitScreen) = false
      },
      circuitNavigator = CircuitNavigator.NoOp,
    )
  }
}

private val log = Logger("Navigator")
