package app.megachat.client.ui.design.user.boid

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.Stable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.platform.LocalInspectionMode
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.DpSize
import androidx.compose.ui.unit.dp
import app.megachat.client.ui.design.boid.BoidSimulator
import app.megachat.client.ui.design.boid.BoundsRule
import app.megachat.client.ui.design.boid.GravityRule
import app.megachat.client.ui.design.boid.KeepDistanceRule
import app.megachat.client.ui.design.boid.MoveToCenterRule
import app.megachat.client.ui.design.boid.Vector3d
import app.megachat.client.ui.design.util.DpOffset
import app.megachat.client.ui.design.util.degrees
import app.megachat.client.ui.design.util.withDeltaSeconds
import app.megachat.shared.base.data.UserId
import app.megachat.shared.base.util.emptyPersistentMap
import app.megachat.shared.base.util.immutableListOf
import kotlin.math.roundToInt
import kotlin.random.Random
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.collections.immutable.ImmutableSet
import kotlinx.collections.immutable.PersistentMap
import kotlinx.collections.immutable.toPersistentMap
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch

@Composable
fun rememberUserBoidsState(
  userIds: ImmutableSet<UserId>,
  bouncer: UserBoidsBouncer = rememberUserBoidsBouncer(userIds),
  animated: Boolean,
): UserBoidsState {
  val state = if (LocalInspectionMode.current) {
    remember {
      UserBoidsState(
        bouncer = bouncer,
        random = Random(1),
        initialBounds = Vector3d(x = 400.dp, y = 200.dp, z = 100.dp),
      )
    }
  } else {
    remember {
      UserBoidsState(bouncer)
    }
  }.also {
    it.update()
  }

  LaunchedEffect(state, animated) {
    if (animated) state.animate()
  }

  return state
}

@Stable
class UserBoidsState(
  private val bouncer: UserBoidsBouncer,
  private val random: Random = Random,
  initialBounds: Vector3d = Vector3d.Zero,
) {

  val gravity = mutableStateOf(Vector3d.Zero)

  internal var boids: PersistentMap<UserId, UserBoid> by mutableStateOf(emptyPersistentMap())
    private set

  private var bounds by mutableStateOf(initialBounds)

  private fun recomputeBoids() {
    if (bounds == Vector3d.Zero) return

    boids = bouncer.userIds.entries.associateBy(keySelector = { it.key }) { (userId, showAvatar) ->
      boids.getOrElse(userId) {
        UserBoid(
          userId = userId,
          initialCenter = with (bounds) {
            Vector3d(
              x = x * random.nextFloat(),
              y = y * random.nextFloat(),
              z = z * random.nextFloat(),
            )
          },
          initialVelocity = DpOffset(angle = 360.degrees * random.nextFloat(), distance = 100.dp)
            .to3d(z = 6.dp + 4.dp * random.nextFloat())
        )
      }
        .also { boid ->
          boid.showAvatar = showAvatar
        }
    }.toPersistentMap()
  }

  private val simulation = object : BoidSimulator.Simulation<UserId, UserBoid> {
    override val boids get() = this@UserBoidsState.boids
    override val bounds get() = this@UserBoidsState.bounds
  }

  private val simulator = BoidSimulator(
    simulation = simulation,
    rules = immutableListOf(
      MoveToCenterRule(factor = 1 / 200f),
      KeepDistanceRule(),
      BoundsRule(factor = 5f),
      GravityRule(gravity),
    )
  )

  fun update() {
    recomputeBoids()
  }

  fun updateBounds(size: DpSize) {
    bounds = Vector3d(
      x = size.width,
      y = size.height,
      z = MaxZ,
    )
    bouncer.update(
      maxVisibleBoids = 2 + (bounds.x.value * bounds.y.value).roundToInt() / SparseBoidArea,
    )
    recomputeBoids()
  }

  @Stable
  inner class UserBoid(
    val userId: UserId,
    initialCenter: Vector3d,
    initialVelocity: Vector3d,
  ) : BoidSimulator.Boid<UserId> {
    override val id get() = userId
    override var center: Vector3d by mutableStateOf(initialCenter)
    override var velocity: Vector3d by mutableStateOf(initialVelocity)
    override val radius get() = 32.dp
    override val weight = -1f // lighter than air

    var showAvatar by mutableStateOf(true)
    val showMood by derivedStateOf { showAvatar && (center.z / MaxZ) >= 0.5f }
  }

  suspend fun animate(
    velocityUpdateInterval: Duration = 100.milliseconds,
  ): Unit = coroutineScope {
    launch {
      while (isActive) {
        boids.values.forEach { boid ->
          boid.velocity = simulator.newVelocityFor(boid)
        }
        // Velocities can get really jerky if updated every frame
        // So update them asynchronously from the movement animation
        delay(velocityUpdateInterval)
      }
    }
    launch {
      withDeltaSeconds { ds ->
        boids.values.forEach { boid ->
          boid.center += boid.velocity.times(ds.coerceAtMost(1f))
        }
      }
    }
  }

  companion object {
    // NOTE: when a boid is at MaxZ, they are biggest; "nearest the top"
    val MaxZ = 128.dp

    const val SparseBoidArea = 100 * 100
  }
}

private fun DpOffset.to3d(z: Dp = 0.dp): Vector3d = Vector3d(x = x, y = y, z = z)
