package app.megachat.client.ui.design.custom

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import app.megachat.client.ui.design.draw.angledSweepGradient

@Composable
fun rememberCustomBrush(
  customBrush: CustomBrush?,
): Brush? =
  remember(customBrush) {
    when (customBrush) {
      is CustomBrush.Solid ->
        if (customBrush.color.alpha > 0f) SolidColor(customBrush.color) else null

      is CustomBrush.SweepRgb -> CustomBrushes.SweepRgb

      is CustomBrush.Unknown, null -> null
    }
  }

object CustomBrushes {

  val SweepRgb = Brush.angledSweepGradient(
    listOf(Color.Red, Color(0xFF00AF00), Color.Blue, Color.Red),
    startAngle = -80f,
  )
}
