package app.megachat.shared.base.util

import kotlin.coroutines.CoroutineContext
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.Runnable

/**
 * Unlike the [Main.immediate] and [Unconfined.immediate], this dispatcher is *always* immediate.
 */
object ImmediateDispatcher : CoroutineDispatcher() {
  override fun dispatch(context: CoroutineContext, block: Runnable) {
    block.run()
  }
}
