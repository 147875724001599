package app.megachat.client.ui.design.user.avatar

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.BoxWithConstraintsScope
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.size
import androidx.compose.material3.LocalTextStyle
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalInspectionMode
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import app.megachat.client.ui.design.components.Text
import app.megachat.client.ui.design.custom.CustomShape
import app.megachat.client.ui.design.custom.rememberCustomBorder
import app.megachat.client.ui.design.custom.rememberCustomBrush
import app.megachat.client.ui.design.custom.rememberCustomShape
import app.megachat.client.ui.design.media.Image
import app.megachat.client.ui.design.theme.AppTheme
import app.megachat.client.ui.design.user.UserSummary
import app.megachat.client.ui.design.user.status.OnlineStatusIndicator
import app.megachat.shared.base.util.firstVisibleCharOrNull
import app.megachat.shared.base.util.runIfNotNull
import app.megachat.shared.base.util.splitWords

@Composable
fun UserAvatar(
  user: UserSummary?,
  backgroundColor: Color = AppTheme.backgroundColor,
  foregroundBrush: Brush = AppTheme.onBackgroundBrush,
  modifier: Modifier = Modifier,
) {
  val shape = rememberCustomShape(user?.avatarStyle?.shape)

  BoxWithConstraints(
    modifier = modifier
      .aspectRatio(1f),
  ) {
    val borderBrush = rememberCustomBrush(
      user?.avatarStyle?.brush,
    )

    val border = rememberCustomBorder(
      user?.avatarStyle?.border,
      brush = borderBrush ?: foregroundBrush,
      shape = shape,
      size = maxWidth,
    )

    var imageLoaded by remember { mutableStateOf(false) }

    Image(
      modifier = Modifier
        .matchParentSize()
        .clip(shape)
        .background(backgroundColor)
        .runIfNotNull(border) { then(it) },
      url = user?.avatarImage?.url,
      onSuccess = { imageLoaded = true },
      contentScale = ContentScale.Crop,
    )

    // TODO: Looks like in previews, an invisible fake image loads?
    if (!imageLoaded || LocalInspectionMode.current) {
      Initials(
        modifier = Modifier
          .align(Alignment.Center),
        displayName = user?.displayName,
        brush = borderBrush ?: foregroundBrush,
        scale = rememberInitialsScale(user?.avatarStyle?.shape),
      )
    }

    OnlineStatusIndicator(
      onlineStatus = user?.onlineStatus,
      modifier = Modifier
        .align(Alignment.BottomEnd)
        .size(maxWidth * 0.2f),
    )
  }
}

@Composable
private fun BoxWithConstraintsScope.Initials(
  displayName: String?,
  brush: Brush?,
  scale: Float,
  modifier: Modifier = Modifier,
) {
  val initials = remember(displayName) {
    displayName
      ?.splitWords(limit = 2)
      ?.joinToString(separator = "") { it.firstVisibleCharOrNull() ?: "" }
      ?.takeIf { it.isNotEmpty() }
      ?.uppercase()
  }

  if (initials != null) {
    Text(
      text = initials,
      fontSize = with(LocalDensity.current) { maxHeight.toSp() * scale },
      modifier = modifier.align(Alignment.Center),
      textAlign = TextAlign.Center,
      overflow = TextOverflow.Visible,
      softWrap = false,
      maxLines = 1,
      style = LocalTextStyle.current.copy(
        brush = brush,
      )
    )
  }
}

@Composable
private fun rememberInitialsScale(avatarShape: CustomShape?): Float =
  remember(avatarShape) {
    when (avatarShape) {
      is CustomShape.Circle -> 0.5f
      is CustomShape.Heart -> 0.5f
      is CustomShape.Hexagon35H -> 0.45f
      is CustomShape.Hexagon35V -> 0.45f
      is CustomShape.Octagon40H -> 0.5f
      is CustomShape.Square20H -> 0.5f
      is CustomShape.Square20V -> 0.4f
      is CustomShape.Triangle50H -> 0.35f
      is CustomShape.Triangle50V -> 0.35f
      is CustomShape.Unknown, null -> 0.5f // Default is Circle
    }
  }
