package app.megachat.shared.base.util

import kotlinx.collections.immutable.ImmutableList
import kotlinx.collections.immutable.persistentListOf
import kotlinx.collections.immutable.toPersistentList

@Suppress("unchecked_cast")
fun <E> emptyImmutableList(): ImmutableList<E> =
  emptyImmutableList as ImmutableList<E>

inline fun <E> immutableListOf(vararg elements: E): ImmutableList<E> =
  persistentListOf(*elements)

inline fun <E> Iterable<E>.toImmutableList(): ImmutableList<E> =
  toPersistentList()

inline fun <E> Sequence<E>.toImmutableList(): ImmutableList<E> =
  toPersistentList()

private val emptyImmutableList = persistentListOf<Unit>()
