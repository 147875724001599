package app.megachat.website.koin

import app.megachat.shared.base.koin.rootScope
import app.megachat.shared.base.log.KoinLogger
import app.megachat.shared.base.log.Logger
import app.megachat.shared.base.util.ImmediateDispatcher
import app.megachat.website.WebsiteModule
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.koin.core.KoinApplication
import org.koin.dsl.module

internal class KoinScopeManager(
  private val configure: (KoinApplication.() -> Unit)?,
) {

  val koinApplication: KoinApplication = org.koin.core.context.startKoin {
    logger(KoinLogger)
    configure?.invoke(this)
    allowOverride(false)
  }

  private val rootScope = koinApplication.rootScope

  init {
    koinApplication.modules(
      module {
        single { this@KoinScopeManager }
      },
      WebsiteModule,
    )

    // Create singletons with "createdAtStart = true".
    koinApplication.createEagerInstances()

    rootScope.get<CoroutineScope>().launch(ImmediateDispatcher) {
    }
  }

  companion object {
    private val log = Logger("Koin")
  }
}
