package app.megachat.client.ui.design.media

import coil3.ImageLoader
import coil3.PlatformContext

internal actual fun emptyImageLoaders(): ImageLoaders {
  return object : ImageLoaders {
    override val default = ImageLoader.Builder(context = PlatformContext.INSTANCE)
      .build()
  }
}
